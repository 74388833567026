import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { hash } from 'rsvp';

import BasketService from 'mobile-web/services/basket';
import OnPremiseService from 'mobile-web/services/on-premise';

export default class OpenCheckConfirmationRoute extends Route {
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service basket!: BasketService;

  beforeModel() {
    if (!this.onPremise.hasOpenCheck) {
      this.router.replaceWith('index');
    }
  }

  model() {
    const basket = this.basket.basket;
    const vendor = this.basket.basket?.vendor;

    return hash({ basket, vendor });
  }
}
