import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import { computedSession } from 'mobile-web/lib/computed';
import * as Country from 'mobile-web/lib/country';
import { Code } from 'mobile-web/lib/country';
import { ChannelSettings } from 'mobile-web/models/bootstrap-data';
import Channel from 'mobile-web/models/channel';
import { ContentModelShape } from 'mobile-web/models/content';
import HandoffModeModel from 'mobile-web/models/handoff-mode';

import BootstrapService from './bootstrap';

export default class ChannelService extends Service {
  // Service injections
  @service store!: DS.Store;
  @service bootstrap!: BootstrapService;

  // Untracked properties
  @computedSession savedCurrentCountry?: string;

  // Tracked properties

  // Getters and setters
  @alias('current.settings')
  settings?: ChannelSettings;

  get currentCountry(): Code {
    return this.savedCurrentCountry === Code.CA ? Code.CA : Code.US;
  }

  get current(): Channel | undefined {
    const data = this.bootstrap.data;
    if (isNone(data)) {
      return undefined;
    }

    const bootstrapChannel = data.channel;
    if (isNone(bootstrapChannel)) {
      throw new Error('Bootstrap data is missing channel configuration.');
    }

    const channel = this.store.peekRecord('channel', bootstrapChannel.id)!;

    const content: ContentModelShape[] = data.content ?? [];
    if (content.length) {
      this.store.pushPayload('content', { content });
    }

    return channel;
  }

  get androidAppIdentifier() {
    return this.current?.androidAppIdentifier ?? '';
  }

  get iOSAppIdentifier() {
    return this.current?.iOSAppIdentifier ?? '';
  }

  get name(): string {
    return this.current?.name ?? '';
  }

  get handoffModes(): HandoffModeModel[] {
    return this.current?.handoffModes ?? [];
  }

  @computed('countrySettings')
  get firstCountry(): Country.Code {
    return Object.keys(this.countrySettings)[0] as Country.Code;
  }

  @computed('settings.channelCountrySettingsByCountryCode')
  get countrySettings(): Country.SettingsPayload {
    return this.settings?.channelCountrySettingsByCountryCode ?? Country.defaultSettings();
  }

  @computed('countrySettings')
  get countries(): Country.Country[] {
    const codes = Object.keys(this.countrySettings) as Country.Code[];
    return codes.map(code => ({ code, name: this.countrySettings[code]!.name }));
  }

  @computed('countries', 'currentCountry', 'firstCountry')
  get countryConfig(): Country.Config {
    return { current: this.currentCountry ?? this.firstCountry, supported: this.countries };
  }

  @computed(
    'channel.{countrySettings,currentCountry}',
    'countrySettings',
    'currentCountry',
    'firstCountry'
  )
  get currentCountrySettings(): Country.SettingsPayload[Country.Code] {
    const countrySettings = this.countrySettings;

    const currentCountrySettings = countrySettings[this.currentCountry || this.firstCountry];
    return currentCountrySettings || countrySettings[this.firstCountry];
  }

  @computed('current')
  get heroImageUrl(): string {
    return this.buildCdnImageUrl('/hero/mobile.png');
  }

  // Lifecycle methods

  // Other methods
  buildCdnImageUrl(path: string): string {
    const current = this.current;
    if (!current) {
      return '';
    }
    const cacheVersion = this.settings?.cacheVersion;
    const cacheQueryString = isNone(cacheVersion) ? '' : `?v=${cacheVersion}`;
    return `${current.cdnUrl}/img/${current.internalName}${path}${cacheQueryString}`;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    channel: ChannelService;
  }
}
