import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import {
  getHandoffLabel,
  getTimeWantedTypeLabel,
  isAdvance,
  OrderCriteria,
} from 'mobile-web/lib/order-criteria';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import MwcIntl from 'mobile-web/services/mwc-intl';

type LabelType = 'time' | 'handoff';

export interface Signature {
  Args: {
    Named: { type?: LabelType; criteria?: OrderCriteria; html?: boolean };
    Positional: unknown[];
  };
  Return: string;
}

/**
 * TODO: Refactor this helper into two separate helpers (OLO-26083
):
 * - TimeCriteriaLabel
 * - HandoffCriteriaLabel
 *
 * in breaking these cases out into separate helpers, each helper can be simplified.
 */
export default class CriteriaLabel extends Helper<Signature> {
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service mwcIntl!: MwcIntl;
  @service store!: DS.Store;

  compute(
    _params?: Signature['Args']['Positional'],
    { type, criteria, html }: Signature['Args']['Named'] = {}
  ): Signature['Return'] {
    if (!criteria) {
      throw new Error(`'criteria' is required`);
    }

    switch (type) {
      case 'time': {
        if (this.features.flags['abtest-asap-scheduled-dictionary-default']) {
          const time = this.basket.basket?.leadTimeEstimate;
          if (
            criteria.timeWantedType === 'Immediate' &&
            this.basket.basketProducts.length &&
            time
          ) {
            const label = getTimeWantedTypeLabel(criteria.timeWantedType, this.store);
            const plural = time !== 1 ? 's' : '';
            return html ? `<strong>${label} (${time} min${plural} est.)</strong>` : label;
          }
        }

        if (!isAdvance(criteria) || !criteria.timeWanted) {
          const label = getTimeWantedTypeLabel(criteria.timeWantedType, this.store);
          return html ? `<strong>${label}</strong>` : label;
        }

        const timeZone = this.basket.basket?.vendor?.get('timeZoneId');
        if (timeZone) {
          return this.mwcIntl.vendorRelativeDateTime(criteria.timeWanted.toDate(), timeZone, {
            html,
          });
        }

        return this.mwcIntl.relativeDateTime(criteria.timeWanted, {
          html,
          titleCase: true,
        });
      }
      case 'handoff':
        return getHandoffLabel(criteria.handoffMode, this.store);
      default:
        throw new Error(`'type' must be 'time' or 'handoff'`);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'criteria-label': typeof CriteriaLabel;
  }
}
