import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import { SECONDS } from 'mobile-web/lib/time/durations';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import GroupOrderService from 'mobile-web/services/group-order';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Signature {
  Element: HTMLElement;
}

export default class GroupOrderInviteModal extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service vendor!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked copyLinkButtonLabel: string = this.intl.t('mwc.groupOrder.inviteCopyButton');
  @tracked copyLinkButtonIconName = 'icon';

  // Getters and setters
  get groupOrderInviteUrl(): string {
    const fullSiteUrl = this.channel.settings?.fullSiteUrl ?? '';
    const groupOrderId = this.groupOrder.groupOrderId ?? '';
    return `https://${fullSiteUrl}/group-order/join?groupOrderId=${groupOrderId}`;
  }

  get canShare(): boolean {
    return !!navigator.share;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  copyLink() {
    this.analytics.trackEvent(AnalyticsEvents.GroupOrderInviteOthersCopyLink);
    navigator.clipboard.writeText(this.groupOrderInviteUrl);

    this.copyLinkButtonLabel = this.intl.t('mwc.groupOrder.inviteLinkCopied');
    this.copyLinkButtonIconName = 'check';

    later(() => {
      this.copyLinkButtonLabel = this.intl.t('mwc.groupOrder.inviteCopyButton');
      this.copyLinkButtonIconName = 'copy';
    }, 2 * SECONDS);
  }

  @action
  async shareLink() {
    const shareDescription = `${this.intl.t('mwc.groupOrder.shareDescription')} ${
      this.channel.name
    }!`;
    this.analytics.trackEvent(AnalyticsEvents.GroupOrderInviteOthersShareLink);
    const shareData = {
      title: shareDescription,
      text: shareDescription,
      url: this.groupOrderInviteUrl,
    };

    try {
      await navigator.share(shareData);
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        // do nothing
      } else {
        this.error.reportError(e);
      }
    }
  }

  @action
  onClose() {
    this.groupOrder.showInviteModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::InviteModal': typeof GroupOrderInviteModal;
  }
}
