import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import DS from 'ember-data';

import IntlService, { TOptions } from 'ember-intl/services/intl';

import FeaturesService from './features';

export type SafeString = ReturnType<typeof htmlSafe>;
export const EmptySafeString = htmlSafe('');
export type ContentString = string | SafeString;

export type ContentData = {
  toTemplateString(): string;
  toString(): string;
};

export default class ContentService extends Service {
  // Service injections
  @service intl!: IntlService;
  @service store!: DS.Store;
  @service features!: FeaturesService;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  /**
   * Convert a `string` type into a ContentData.
   * This is used for interoperability with other ContentData
   * such as Translations or Dictionary entries.
   *
   * @param str the string to use
   * @returns the given string in a ContentData
   */
  getStringData(str: string): Promise<ContentData> {
    return Promise.resolve({
      toTemplateString: () => str,
      toString: () => str,
    });
  }

  /**
   * Get a translation entry with no fallbacks.
   *
   * @param key the key to look up in the translation
   * @param options the replacements to use to interpolate values into the translation
   * @returns the translation entry in a ContentData, or undefined if it could not be found
   */
  getTranslationData(key: string, options?: TOptions): Promise<ContentData | undefined> {
    return new Promise(res => {
      if (this.intl.exists(key)) {
        res({
          toTemplateString: () => this.intl.lookup(key)!,
          toString: () => this.intl.t(key, options).toString(),
        });
      } else {
        res(undefined);
      }
    });
  }

  /**
   * Get a translation entry with fallback to empty string. Always returns a
   * ContentData.
   *
   * @param key the key to look up in the translation
   * @param options the replacements to use to interpolate values into the translation
   * @returns the translation entry in a ContentData, or an empty string in a
   * ContentData if the translation could not be found
   */
  getTranslationEntry(key: string, options?: TOptions): Promise<ContentData> {
    return this.getTranslationData(key, options).then(translation => {
      if (translation) {
        return translation;
      }
      return this.getStringData('');
    });
  }

  /**
   * Get a dictionary entry with no fallbacks.
   *
   * @param key the key to look up in the dictionary
   * @param replacements the replacements to use to replace `%tokens%` in the
   * dictionary entry. If a translation is provided as a fallback, the
   * replacements will be passed to `IntlService` as well.
   * @returns the dictionary entry in a ContentData, or undefined if it could not be found
   */
  getDictionaryData(
    key: string,
    replacements?: Record<string, string>
  ): Promise<ContentData | undefined> {
    return this.store.findRecord('content', key).then(
      record => {
        if (record?.value) {
          return {
            toTemplateString: () => record.value,
            toString: () =>
              replacements ? this.substitute(record.value, replacements) : record.value,
          };
        }
        return undefined;
      },
      _ => undefined
    );
  }

  /**
   * Get a dictionary entry with an optional translation fallback and optional
   * token replacements. Returns a ContentData instead of a string.
   *
   * @param key the key to look up in the dictionary
   * @param fallback the fallback to use if the dictionary entry doesn't exist.
   * If an extant translation key is used (e.g. 'mwc.example'), the translation
   * will be read from `IntlService`.
   * @param replacements the replacements to use to replace `%tokens%` in the
   * dictionary entry. If a translation is provided as a fallback, the
   * replacements will be passed to `IntlService` as well.
   * @returns the dictionary entry or fallback with tokens replaced as ContentData.
   */
  getData(key: string): Promise<ContentData>;
  getData(key: string, fallback: string): Promise<ContentData>;
  getData(key: string, replacements: Record<string, string>): Promise<ContentData>;
  getData(
    key: string,
    fallback: string,
    replacements: Record<string, string>
  ): Promise<ContentData>;
  getData(
    key: string,
    fallback: string,
    replacements: Record<string, string>,
    experimentFeatureFlagKey: string
  ): Promise<ContentData>;
  async getData(
    ...args:
      | [string]
      | [string, string]
      | [string, Record<string, string>]
      | [string, string, Record<string, string>]
      | [string, string, Record<string, string>, string]
  ): Promise<ContentData> {
    let key: string;
    let fallback: string | undefined;
    let replacements: Record<string, string>;
    let experimentFeatureFlagKey: string | undefined;

    switch (args.length) {
      case 1:
        [key] = args;
        replacements = {};
        break;
      case 2:
        if (typeof args[1] === 'string') {
          [key, fallback] = args;
          replacements = {};
        } else {
          [key, replacements] = args;
        }
        break;
      case 3:
        [key, fallback, replacements] = args;
        break;
      case 4:
      default:
        [key, fallback, replacements, experimentFeatureFlagKey] = args;
        break;
    }

    if (!!experimentFeatureFlagKey && !!fallback) {
      return this.getExperimentalContent(key, replacements, fallback!, experimentFeatureFlagKey!);
    }

    //no experiment
    return this.getDictionaryOrFallbackContent(key, replacements, fallback);
  }

  private getDictionaryOrFallbackContent(
    key: string,
    replacements: Record<string, string>,
    fallback: string | undefined
  ): ContentData | PromiseLike<ContentData> {
    return this.getDictionaryData(key, replacements).then(content => {
      if (content?.toString()) {
        return content;
      }

      if (fallback) {
        return this.getTranslationData(fallback, replacements).then(c =>
          c ? c : this.getStringData(fallback ?? '')
        );
      }

      return this.getStringData('');
    });
  }

  private async getExperimentalContent(
    key: string,
    replacements: Record<string, string>,
    fallback: string,
    experimentFeatureFlagKey: string
  ): Promise<ContentData> {
    const content = await this.getDictionaryData(key, replacements);
    const dictionaryContent = content?.toString()
      ? content
      : { toString: () => '', toTemplateString: () => '' };

    const fallbackData = await this.getTranslationData(fallback!, replacements);
    const fallbackContent = fallbackData ?? (await this.getStringData(fallback ?? ''));
    const contents = { dictionaryContent, fallbackContent }; //pre-load dictionary and fallback content

    const dictionaryStringContent = contents.dictionaryContent?.toString();
    const fallbackStringContent = contents.fallbackContent?.toString();
    const hasCustomizedDictionaryContent = dictionaryStringContent !== fallbackStringContent;

    if (hasCustomizedDictionaryContent) {
      //only run the experiment if the dictionary hasn't been overridden
      return contents.dictionaryContent;
    }

    const featureFlagValue = this.features.flags[experimentFeatureFlagKey!]?.toString();

    if (featureFlagValue && featureFlagValue.trim() !== '') {
      //flag value is the experimental content
      return {
        toTemplateString: () => featureFlagValue,
        toString: () =>
          replacements ? this.substitute(featureFlagValue, replacements) : featureFlagValue,
      };
    }

    return contents.fallbackContent; //fallback is the same as dictionary
  }

  /**
   * Get a dictionary entry with an optional translation fallback and optional
   * token replacements.
   *
   * @param key the key to look up in the dictionary
   * @param fallback the fallback to use if the dictionary entry doesn't exist.
   * If an extant translation key is used (e.g. 'mwc.example'), the translation
   * will be read from `IntlService`.
   * @param replacements the replacements to use to replace `%tokens%` in the
   * dictionary entry. If a translation is provided as a fallback, the
   * replacements will be passed to `IntlService` as well.
   * @returns the dictionary entry or fallback with tokens replaced.
   */
  getEntry(key: string): Promise<string>;
  getEntry(key: string, fallback: string): Promise<string>;
  getEntry(key: string, replacements: Record<string, string>): Promise<string>;
  getEntry(key: string, fallback: string, replacements: Record<string, string>): Promise<string>;
  getEntry(
    key: string,
    fallback: string,
    replacements: Record<string, string>,
    experimentFeatureFlagKey?: string
  ): Promise<string>;
  async getEntry(
    ...args:
      | [string]
      | [string, string]
      | [string, Record<string, string>]
      | [string, string, Record<string, string>]
      | [string, string, Record<string, string>, string?]
  ): Promise<string> {
    return this.getData(...(args as Parameters<typeof this.getData>)).then(data => data.toString());
  }

  /**
   * Get an HTML dictionary entry with an optional translation fallback and
   * optional token replacements.
   *
   * The entry is sanitized against trivial XSS attacks.
   *
   * @param key the key to look up in the dictionary
   * @param fallback the fallback to use if the dictionary entry doesn't exist.
   * If an extant translation key is used (e.g. 'mwc.example'), the translation
   * will be read from `IntlService`.
   * @param replacements the replacements to use to replace `%tokens%` in the
   * dictionary entry. If a translation is provided as a fallback, the
   * replacements will be passed to `IntlService` as well.
   * @returns the dictionary entry or fallback with tokens replaced.
   */
  getHTML(key: string): Promise<SafeString>;
  getHTML(key: string, fallback: string): Promise<SafeString>;
  getHTML(key: string, replacements: Record<string, string>): Promise<SafeString>;
  getHTML(key: string, fallback: string, replacements: Record<string, string>): Promise<SafeString>;
  getHTML(
    key: string,
    fallback: string,
    replacements: Record<string, string>,
    experimentFeatureFlagKey?: string
  ): Promise<SafeString>;
  async getHTML(
    ...args:
      | [string]
      | [string, string]
      | [string, Record<string, string>]
      | [string, string, Record<string, string>]
      | [string, string, Record<string, string>, string?]
  ): Promise<SafeString> {
    const rawEntry = await this.getEntry(...(args as Parameters<typeof this.getEntry>));

    return this.dangerouslySanitizeTrustedContent(rawEntry);
  }

  /**
   * ⚠ UNSAFE: this method is _NOT_ to be used for raw user input. This method
   * is intended to prevent only the most trivial of XSS accidents when
   * rendering trusted content from admin/dashboard. To that end, this method
   * cleans up HTML in the following ways _only_:
   *
   *  - removes all `on*` attributes from elements (e.g. `onclick`)
   *  - removes hrefs from links using the `javascript:` protocol
   *  - removes `<script>` and `<form>` elements and their child nodes
   *
   * @param rawHTML the raw HTML to "sanitize"
   * @returns the "sanitized" HTML.
   */
  dangerouslySanitizeTrustedContent(rawHTML: string): SafeString {
    const stage = document.createElement('div');

    stage.innerHTML = rawHTML;

    stage.querySelectorAll('script, form').forEach(el => el.remove());

    stage.querySelectorAll('*').forEach(el => {
      el.getAttributeNames()
        .filter(attr => /^on/.test(attr))
        .forEach(attr => el.removeAttribute(attr));
    });

    stage.querySelectorAll<HTMLAnchorElement>('a[href]').forEach(el => {
      // eslint-disable-next-line no-script-url
      if (el.protocol === 'javascript:') {
        el.removeAttribute('href');
      }
    });

    return htmlSafe(stage.innerHTML);
  }

  /**
   * Substitutes tokens in the input by looking up the keys from the
   * substitutions map and replacing the tokens with the paired value.
   *
   * Substitutions are surrounded by % characters in the input.
   *
   * @param input The input string that c
   * @param substitutions
   * @returns the replaced string
   *
   * @example
   * this.substitute('Hello, %thing%!', {
   *   thing: 'World'
   * });
   * // produces Hello, World!
   */
  private substitute(input: string, substitutions: Record<string, string>): string {
    const subKeys = Object.keys(substitutions);

    return input.replace(/%(\w+)%/gi, (match, key) => {
      const substitutionKey = subKeys.find(
        subKey => subKey.localeCompare(key, undefined, { sensitivity: 'base' }) === 0
      );

      return substitutionKey ? substitutions[substitutionKey] : match;
    });
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    content: ContentService;
  }
}
