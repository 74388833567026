import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { MediaService } from 'ember-responsive';
import pick from 'lodash.pick';

import ProductModel, { MIMGroup, MIMImage } from 'mobile-web/models/product';
import VendorModel from 'mobile-web/models/vendor';
import FeaturesService from 'mobile-web/services/features';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import StorageService from 'mobile-web/services/storage';

// eslint-disable-next-line no-use-before-define
export type GlobalCategory = Pick<CategoryModel, 'name' | 'description'>;

export default class CategoryModel extends DS.Model {
  @service media!: MediaService;
  @service orderCriteria!: OrderCriteriaService;
  @service store!: DS.Store;
  @service storage!: StorageService;
  @service features!: FeaturesService;

  @DS.attr()
  name!: string;
  @DS.attr()
  description!: string;
  @DS.attr()
  isSingleUse!: boolean;
  @DS.attr('array', { defaultValue: () => [] })
  images!: MIMImage[];
  @DS.attr('string')
  brandName?: string;

  @DS.hasMany('product')
  products!: DS.PromiseManyArray<ProductModel>;

  @DS.belongsTo('vendor', { async: false })
  vendor!: VendorModel;

  get bannerImage(): string {
    const groupName = this.media.isTablet ? MIMGroup.ResponsiveLarge : MIMGroup.Customize;
    return this.images.find(i => i.groupName === groupName)?.filename ?? '';
  }

  get inActiveBrand(): boolean {
    const activeBrand = this.store.peekAll('brand').filter(b => b.isActive === true).firstObject;
    return !activeBrand || this.brandName === activeBrand.brandName;
  }

  get isAvailable(): boolean {
    return this.products.any(p => p.isVisible);
  }

  get isHidden(): boolean {
    // If category has no visible products, should be hidden
    if (!this.features.flags['brs-sticky-nav-refactor-olo-47894']) {
      return !this.products.any(p => p.isVisible);
    }

    return !this.products.any(p => p.isVisible) || !this.inActiveBrand;
  }

  /**
   * @deprecated remove with `brs-sticky-nav-refactor-olo-47894`. This has been
   * merged into the logic of `isHidden`.
   */
  get isNotInActiveBrand(): boolean {
    const activeBrand = this.store.peekAll('brand').filter(b => b.isActive === true).firstObject;
    if (activeBrand) {
      return this.brandName !== activeBrand?.brandName;
    }
    return false;
  }

  get productIds(): EmberDataId[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.hasMany('products' as any).ids();
  }

  serializeForGlobalData(): GlobalCategory {
    return pick(this, 'name', 'description');
  }
}
