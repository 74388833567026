export default {
  "root": "_root_1fqo5d",
  "formFields": "_formFields_1fqo5d",
  "formField": "_formField_1fqo5d",
  "startTab": "_startTab_1fqo5d",
  "submitContainer": "_submitContainer_1fqo5d",
  "submitButton": "_submitButton_1fqo5d",
  "softDisabledProccedAsGuestButton": "_softDisabledProccedAsGuestButton_1fqo5d",
  "openCheckButtonContainer": "_openCheckButtonContainer_1fqo5d",
  "loadingIcon": "_loadingIcon_1fqo5d"
};
