import { action } from '@ember/object';
import { addObserver } from '@ember/object/observers';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import ld, { LDClient, LDFlagSet, LDFlagValue, LDUser } from 'launchdarkly-js-client-sdk';
import { v4 as uuidv4 } from 'uuid';

import ENV from 'mobile-web/config/environment';
import { computedLocal } from 'mobile-web/lib/computed';
import AnalyticsService, { AnalyticsProperties } from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import DeviceService from 'mobile-web/services/device';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

import OrderCriteriaService from './order-criteria';

export const AB_TEST_FLAG_PREFIX = 'abtest-';

export enum Metrics {
  OrderPlaced = 'Order Placed',
}

export default class FeaturesService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bootstrap!: BootstrapService;
  @service device!: DeviceService;
  @service storage!: StorageService;
  @service vendor!: VendorService;
  @service orderCriteria!: OrderCriteriaService;

  // Untracked properties
  private ldClient!: LDClient;
  private ldInit!: Promise<void>;
  @computedLocal private ldUserId?: string;
  private retryDelay = 1000;
  private retryTimer!: NodeJS.Timeout;
  private _customUserData: UnknownObject = {};
  private trackedMixpanelFlags = new Set<string>();
  /*
   * When LD becomes unavailable, all FFs become `false`.
   * If they should have a different default value, add them here.
   */
  private defaultFlags: LDFlagSet = {
    'sms-pilot-vendors': '',
  };

  // Tracked properties
  @tracked connected = false;
  @tracked private _flags: LDFlagSet = {};

  // Getters and setters
  get userKey(): string | undefined {
    return this.bootstrap.data?.mixpanelUniqueId || this.ldUserId;
  }

  get flags(): LDFlagSet {
    return this.connected ? this._flags : this.defaultFlags;
  }

  get allFlags(): LDFlagSet {
    return this.connected ? this.ldClient.allFlags() : this.defaultFlags;
  }

  get ldUser(): LDUser {
    return {
      key: this.userKey,
      custom: {
        uniqueId: this.ldUserId ?? '',
        channel: this.bootstrap.data?.channel.name ?? '',
        hostname: window.location.hostname,
        platform: this.device.platform,
        viewport: this.device.viewport,
        channelInternalName: this.bootstrap.data?.channel.internalName ?? '',
        handoffMode:
          this.orderCriteria.basketOrderCriteria?.handoffMode ??
          this.orderCriteria.searchOrderCriteria?.handoffMode ??
          this.orderCriteria.defaultOrderCriteria?.handoffMode ??
          '',
        appVersion: window.Olo.appVersion ?? '',
        ...this._customUserData,
        vendorHistoryCount: this.storage.vendorHistory?.length ?? 0,
      },
    };
  }

  // Lifecycle methods

  // Other methods
  async setupFeatureFlags(): Promise<void> {
    if (!ENV.LAUNCH_DARKLY) {
      return Promise.resolve();
    }

    // eslint-disable-next-line ember/no-observers
    addObserver(this.device, 'viewport', this.identifyUser);

    if (!this.ldUserId) {
      this.ldUserId = uuidv4();
    }

    // have to import this way so we can stub this out in the test
    this.ldClient = ld.initialize(ENV.LAUNCH_DARKLY, this.ldUser, {
      bootstrap: 'localStorage',
      evaluationReasons: true,
      sendEventsOnlyForVariation: true,
    });

    this.ldClient.on('ready', this.handleFlagsChange);
    this.ldClient.on('change', this.handleFlagsChange);
    this.ldClient.on('error', this.handleFlagsError);
    this.ldClient.on('failed', this.handleFlagsError);

    return (this.ldInit = this.ldClient.waitUntilReady());
  }

  updateUser(key?: string, value?: string | number): void {
    if (key && value) {
      this._customUserData![key] = value;
    }

    this.identifyUser();
  }

  createflagSetProxy(): LDFlagSet {
    this.trackedMixpanelFlags.clear();
    return new Proxy(this, {
      get(features: FeaturesService, flag: string): LDFlagValue {
        const detail = features.ldClient.variationDetail(flag);
        // Because .variation() only sends an evaluation event the first time it runs,
        // we should also only send the LD Mixpanel event the first time.
        if (flag.startsWith(AB_TEST_FLAG_PREFIX) && !features.trackedMixpanelFlags.has(flag)) {
          features.trackedMixpanelFlags.add(flag);
          features.analytics.trackEvent(`LD ${flag}`, () => ({
            [AnalyticsProperties.FlagValue]: detail.value,
            [AnalyticsProperties.FlagEvalutationReason]: detail.reason,
            [AnalyticsProperties.FlagUserKey]: features.ldUser.key,
          }));
        }
        return detail.value;
      },
    });
  }

  trackMetric(key: string): void {
    this.ldInit?.then(() => {
      if (this.connected) {
        this.ldClient.track(key);
      }
    });
  }

  /**
   * If LD can't initialize, retry at a slowly increasing interval.
   * `identify` is one of the few LD calls that will throw an error
   * if it can't reach the server, so by polling for it we force LD
   * to continously fire `change` or `error` events (handled above).
   * If we don't do this, even when the connection comes back the
   * flag values won't update until some user property changes.
   */
  private retryFetchFlags() {
    clearTimeout(this.retryTimer);

    this.retryDelay *= 1.125;
    this.retryTimer = setTimeout(async () => {
      try {
        await this.ldClient.identify(this.ldUser);
        this.handleFlagsChange();
      } catch (e) {}
    }, this.retryDelay);
  }

  // Tasks

  // Actions and helpers
  @action
  private handleFlagsChange() {
    this.connected = true;
    this._flags = this.ldClient ? this.createflagSetProxy() : {};
  }

  @action
  private handleFlagsError() {
    this.connected = false;
    this.retryFetchFlags();
  }

  @action
  private identifyUser() {
    this.ldInit?.then(() => {
      if (this.connected) {
        const LDUserObj = { ...this.ldUser };

        this.ldClient.identify(LDUserObj);

        if (this.bootstrap.data?.mixpanelUniqueId) {
          this.ldClient.alias(LDUserObj, {
            ...LDUserObj,
            key: this.ldUserId,
          });
        }
      }
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    features: FeaturesService;
  }
}
