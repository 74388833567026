/* eslint-disable no-self-assign */
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import * as AddressForm from 'mobile-web/components/address-form';
import { orderCriteriaProperties } from 'mobile-web/lib/analytics';
import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';
import { EMPTY, isValid } from 'mobile-web/lib/location/address';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import {
  cloneOrderCriteria,
  getAddressFromOrderCriteria,
  getSelectedHandoffModeModel,
  getSelectedTimeWantedMode,
  HandoffMode,
  isAdvance,
  isAtStore,
  isDelivery,
  mapPostalCode,
  OrderCriteria,
  updateOrderCriteria,
} from 'mobile-web/lib/order-criteria';
import { safeNext } from 'mobile-web/lib/runloop';
import { guids } from 'mobile-web/lib/utilities/guids';
import SavedAddressModel from 'mobile-web/models/address';
import HandoffModeModel from 'mobile-web/models/handoff-mode';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

export default class EditOrderCriteriaModal extends Component {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service challenge!: ChallengeService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service vendor!: VendorService;
  @service features!: FeaturesService;

  // Untracked properties
  ids = guids(this, 'form');
  style = style;

  // Tracked properties
  @tracked showChangeLocation = false;
  @tracked addressForm!: AddressForm.Model;
  @tracked editingCriteria?: OrderCriteria;

  // Getters and setters
  get showVendorModes(): boolean {
    return this.orderCriteria.showVendorModes;
  }

  get showPrivateModes(): boolean {
    return this.orderCriteria.showPrivateModes;
  }

  get handoffModes(): Array<HandoffModeModel> {
    return this.orderCriteria.selectableHandoffModes;
  }

  get timeWantedTypes(): Array<TimeWantedMode> {
    return this.orderCriteria.getSelectableTimeWantedModes(this.selectedHandoffModeModel);
  }

  get selectedHandoffModeModel(): HandoffModeModel | undefined {
    return getSelectedHandoffModeModel(this.handoffModes, this.editingCriteria?.handoffMode);
  }

  get selectedHandoffMode(): HandoffMode | undefined {
    return this.selectedHandoffModeModel?.type;
  }

  set selectedHandoffMode(hm: HandoffMode | undefined) {
    if (this.editingCriteria && hm) {
      const selectedHandoffModel =
        this.handoffModes.find(model => model.type === hm) ?? this.handoffModes[0];

      updateOrderCriteria(this, 'editingCriteria', { handoffMode: selectedHandoffModel.type });

      // See if the new handoff mode supports the currently selected time mode
      if (
        !selectedHandoffModel.timeWantedModes.any(
          tm => tm.type === this.selectedTimeWantedMode?.type
        )
      ) {
        this.changeTimeWantedMode(selectedHandoffModel.timeWantedModes.firstObject!);
      }
      this.tryShowDeliveryAddressWarning(this.editingCriteria);
    }
  }

  get selectedTimeWantedMode(): TimeWantedMode | undefined {
    return getSelectedTimeWantedMode(this.timeWantedTypes, this.editingCriteria?.timeWantedType);
  }

  get selectedTimeWanted(): Dayjs | undefined {
    return isAdvance(this.editingCriteria) ? this.editingCriteria.timeWanted : undefined;
  }

  get isSearch(): boolean {
    return this.orderCriteria.isSearch;
  }

  get isDelivery(): boolean {
    return isDelivery(this.editingCriteria);
  }

  get showAddress(): boolean {
    return (
      this.isDelivery ||
      // allow the user to change their search address on the search page
      this.router.isActive('vendor-search-results')
    );
  }

  get modalTitle(): string {
    if (this.features.allFlags['abtest-remove-timemode-home-screen-v2'] === 'Test Variant B') {
      return this.intl.t('mwc.orderCriteria.editOrderDetailsText');
    }
    return this.intl.t(`mwc.orderCriteria.${this.isSearch ? 'editSearchTitle' : 'editOrderTitle'}`);
  }

  get addresses(): SavedAddressModel[] {
    return this.store.peekAll('address').toArray();
  }

  get searchAddress(): string {
    return isAtStore(this.editingCriteria) ? this.editingCriteria.searchAddress : '';
  }

  set searchAddress(address) {
    if (isAtStore(this.editingCriteria)) {
      this.editingCriteria.searchAddress = address;
    }
  }

  get isAdvance(): boolean {
    return isAdvance(this.editingCriteria);
  }

  @computed('addressForm.streetAddress', 'addressForm.city', 'addressForm.zipCode')
  get isAddressValid(): boolean {
    return isValid(this.addressForm);
  }

  get valid(): boolean {
    if (isAdvance(this.editingCriteria) && !this.editingCriteria.timeWanted) {
      return false;
    }
    if (isDelivery(this.editingCriteria)) {
      return this.isAddressValid;
    }
    if (!this.showAddress) {
      return true;
    }
    return isAtStore(this.editingCriteria) && !isEmpty(this.editingCriteria.searchAddress);
  }

  // Other methods
  focusOnError() {
    // TODO: Use the FocusManager service
    const focusEl = document.querySelector<HTMLElement>('[data-focusWhen="errorTrigger"]');
    focusEl?.setAttribute('tabindex', '0');
    focusEl?.focus();
  }

  tryShowDeliveryAddressWarning(editingCriteria: OrderCriteria | undefined) {
    if (isDelivery(this.orderCriteria.defaultOrderCriteria) && !this.isAddressValid) {
      if (this.orderCriteria.isMissingDeliveryAddress(editingCriteria)) {
        this.orderCriteria.orderCriteriaError = this.intl.t(
          `mwc.orderCriteria.deliveryAddressWarning`
        );
        this.showChangeLocation = false;
      } else {
        this.orderCriteria.orderCriteriaError = '';
      }
    }
  }

  // Tasks
  saveTask = taskFor(this.saveGenerator);
  @task *saveGenerator(): TaskGenerator<void> {
    if (isDelivery(this.editingCriteria)) {
      if (isEmpty(this.addressForm.id)) {
        try {
          this.channel.savedCurrentCountry = mapPostalCode(this.addressForm.zipCode);
          const addressModel = yield this.store.collectionAction('address', 'addDeliveryAddress', {
            ...this.addressForm,
            basketId: this.basket.basket?.id,
          });
          this.addressForm.id = addressModel.id;
        } catch (e) {
          this.error.reportError(e);
        }
      }
      if (!isEmpty(this.addressForm.id)) {
        updateOrderCriteria(this, 'editingCriteria', {
          deliveryAddress: this.store.peekRecord('address', this.addressForm.id)!,
        });
      }
    }

    if (this.onPremise.tablePosRef) {
      if (this.basket?.basket) {
        // switching basket TO DineIn
        if (this.editingCriteria?.handoffMode === 'DineIn') {
          yield this.basket.basket.setOnPremiseDetails({
            tablePosReference: this.onPremise.tablePosRef,
            experienceType: this.onPremise.experienceType,
          });
        } else {
          // switching basket FROM DineIn
          this.onPremise.tablePosRef = undefined;
          yield this.basket.basket.setOnPremiseDetails({
            tablePosReference: undefined,
            experienceType: this.onPremise.experienceType,
          });
        }
      } else if (this.editingCriteria?.handoffMode !== 'DineIn') {
        // switching criteria FROM DineIn, no basket
        this.onPremise.tablePosRef = undefined;
      }
    }

    const vendor = this.router.isActive('vendor-search-results') ? undefined : this.vendor.vendor;
    if (isAtStore(this.editingCriteria) && isEmpty(this.editingCriteria.searchAddress) && vendor) {
      updateOrderCriteria(this, 'editingCriteria', { searchAddress: vendor.address.postalCode });
    }

    yield taskFor(this.challenge.request).perform(async () => {
      const result = this.editingCriteria
        ? await vendor?.preCheck(this.editingCriteria)
        : undefined;

      if (!result || result.isValid) {
        let success = true;
        if (this.isSearch) {
          if (
            this.editingCriteria?.handoffMode === 'DineIn' &&
            vendor &&
            this.channel.settings?.redirectDineInToMultiOrder
          ) {
            this.onPremise.setExperienceType(OnPremiseExperience.MultiOrder, vendor.slug);
          }
          this.orderCriteria.set('searchOrderCriteria', this.editingCriteria);
        } else {
          await this.orderCriteria.updateBasket().catch(e => {
            success = false;
            this.error.reportError(e);
          });
        }
        if (success) {
          this.features.updateUser();
          this.orderCriteria.closeModal(this.editingCriteria);
          this.bus.trigger('adjustAmounts');
          this.analytics.trackEvent(AnalyticsEvents.ChangeOrderCriteria, () => ({
            [AnalyticsProperties.ChangeLocationShown]: this.showChangeLocation,
            [AnalyticsProperties.TimeWantedShown]: this.timeWantedTypes.length > 0,
            ...orderCriteriaProperties(
              this.handoffModes,
              this.timeWantedTypes,
              this.selectedHandoffModeModel,
              this.selectedTimeWantedMode,
              this.selectedTimeWanted
            ),
            [AnalyticsProperties.Location]: getAddressFromOrderCriteria(this.editingCriteria),
            [AnalyticsProperties.LocationSource]: isDelivery(this.editingCriteria)
              ? 'Multi Field'
              : 'Single Field',
          }));
        }
      } else {
        this.showChangeLocation = true;
        this.orderCriteria.orderCriteriaError = result.errorMessage;
        safeNext(this, this.focusOnError);
      }
    });
  }

  // Actions and helpers
  @action
  setup() {
    const currentCriteria = this.isSearch
      ? this.orderCriteria.searchOrderCriteria
      : this.orderCriteria.basketOrderCriteria;
    this.editingCriteria = cloneOrderCriteria(
      currentCriteria ?? { ...this.orderCriteria.criteria, isDefault: false }
    );

    const timeWantedMode = this.timeWantedTypes.find(
      tw => tw.type === this.editingCriteria?.timeWantedType
    );
    if (timeWantedMode) {
      this.changeTimeWantedMode(timeWantedMode);
    }
    if (isAdvance(this.editingCriteria)) {
      this.changeTimeWanted(this.editingCriteria.timeWanted ?? dayjs());
    }
    if (this.editingCriteria.handoffMode === 'Unspecified') {
      this.selectedHandoffMode = this.orderCriteria.defaultOrderCriteria.handoffMode;
    }

    const currentValue: Partial<AddressForm.Model> = isDelivery(this.editingCriteria)
      ? // can happen if default handoff is delivery or if `handoff=delivery` is
        // specified in the query string
        this.editingCriteria.deliveryAddress ?? EMPTY
      : this.addresses.objectAt(0) ?? {};
    const mode = this.addresses.length > 0 ? AddressForm.Mode.UseExisting : AddressForm.Mode.AddNew;
    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.addressForm = {
      id: currentValue.id || '',
      mode,
      streetAddress: currentValue.streetAddress || '',
      building: currentValue.building || '',
      city: currentValue.city || '',
      zipCode: currentValue.zipCode || '',
    };
    if (!this.orderCriteria.orderCriteriaError) {
      this.tryShowDeliveryAddressWarning(this.editingCriteria);
    }
  }

  @action
  changeLocation() {
    this.orderCriteria.closeModal();
    this.orderCriteria.showAddressModal = true;
  }

  @action
  changeTimeWantedMode(newMode: TimeWantedMode) {
    updateOrderCriteria(this, 'editingCriteria', { timeWantedType: newMode.type });
  }

  @action
  changeTimeWanted(newTimeWanted: Dayjs) {
    if (isAdvance(this.editingCriteria)) {
      updateOrderCriteria(this, 'editingCriteria', { timeWanted: newTimeWanted });
    }
    this.tryShowDeliveryAddressWarning(this.editingCriteria);
  }

  @action
  changeSearchAddress(searchAddress: string) {
    if (isAtStore(this.editingCriteria)) {
      updateOrderCriteria(this, 'editingCriteria', { searchAddress, searchCoords: undefined });
    }
  }

  @action
  save(e?: Event) {
    e?.preventDefault();
    this.saveTask.perform();
  }

  @action
  cancel() {
    this.trackChangeOrderCriteriaCanceled();
    this.orderCriteria.closeModal();
  }

  private trackChangeOrderCriteriaCanceled() {
    const handoffModeModels = this.orderCriteria.selectableHandoffModes;
    const selectedHandoffModeModel = getSelectedHandoffModeModel(
      handoffModeModels,
      this.orderCriteria.criteria.handoffMode
    );
    const timeWantedModes =
      this.orderCriteria.getSelectableTimeWantedModes(selectedHandoffModeModel);
    const selectedTimeWantedMode = getSelectedTimeWantedMode(
      timeWantedModes,
      this.orderCriteria.criteria.timeWantedType
    );

    this.analytics.trackEvent(AnalyticsEvents.ChangeOrderCriteriaCanceled, () => ({
      ...orderCriteriaProperties(
        handoffModeModels,
        timeWantedModes,
        selectedHandoffModeModel,
        selectedTimeWantedMode
      ),
    }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EditOrderCriteriaModal: typeof EditOrderCriteriaModal;
  }
}
