import pick from 'lodash.pick';

import OrderModel from 'mobile-web/models/order';
import UserModel from 'mobile-web/models/user';

export type PastOnPremiseOrder = {
  displayId: string;
  userId: string;
  timePlaced: string;
  totalCost: number;
  basketProducts: Array<{
    productName: string;
    quantity: number;
  }>;
};

export function toPastOnPremiseOrder(user: UserModel, model: OrderModel): PastOnPremiseOrder {
  return {
    displayId: model.displayId,
    totalCost: model.totalCost || 0,
    userId: user.id,
    timePlaced: `${model.timePlacedUtc}Z`,
    basketProducts: model.basketProducts.map(bp => pick(bp, 'productName', 'quantity')),
  };
}

export function keepPastOrders(user?: UserModel, pastOrders?: PastOnPremiseOrder[]): boolean {
  return !!user && !!pastOrders && pastOrders.every(o => o.userId === user.id);
}

export function isJunkEmail(email: string): boolean {
  return /^dinein\+.*@olo\.com$/.test(email);
}

export function isPayAtTableJunkFirstName(name: string): boolean {
  return /Table-[^-]+-Check-[^-]+/.test(name);
}

export type MultiOrderData = {
  readonly start: string;
  readonly continueTime?: string;
  readonly vendorSlug: string;
  readonly pastOrders: PastOnPremiseOrder[];
};

export function multiOrderData(
  vendorSlug: string,
  pastOrders: PastOnPremiseOrder[] = []
): MultiOrderData {
  return {
    start: new Date().toISOString(),
    vendorSlug,
    pastOrders,
  };
}

export enum OnPremiseExperience {
  MultiOrder = 'MultiOrder',
  OpenCheck = 'OpenCheck',
  Default = 'Default',
  PayAtTable = 'PayAtTable',
}
