import Service, { service } from '@ember/service';

import * as signalR from '@microsoft/signalr';

import { checkId } from 'mobile-web/lib/split-check';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';

export default class SplitCheckService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;

  // Untracked properties
  connection?: signalR.HubConnection;

  // Tracked properties

  // Getters and setters
  get checkId() {
    return this.basket.basket ? checkId(this.basket.basket) : undefined;
  }

  // Lifecycle methods

  // Other methods
  async setup() {
    const basket = this.basket.basket;

    if (
      !this.features.flags['split-check-olo-18091'] ||
      !this.onPremise.isPayAtTable ||
      !basket?.onPremiseDetails?.openCheckGuid
    ) {
      return;
    }

    const connection = new signalR.HubConnectionBuilder().withUrl('/splitcheckhub').build();
    connection.on('receiveStatusChange', () => this.fetchStatuses());

    await connection.start();

    await connection.send('join', this.checkId);

    this.connection = connection;
  }

  onStatusChange() {
    if (this.checkId) {
      this.connection?.send('sendStatusChange', this.checkId);
    }
  }

  async fetchStatuses() {
    const statuses = await this.basket.basket?.fetchSplitCheckStatuses();
    if (statuses) {
      const json = JSON.stringify(statuses, undefined, 2);
      alert('Received status change: ' + json);
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'split-check': SplitCheckService;
  }
}
