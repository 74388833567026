import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task } from 'ember-concurrency';
import pick from 'lodash.pick';

import { GuestUser } from 'mobile-web/lib/customer';
import { buildOloErrorHandler } from 'mobile-web/lib/errors';
import LoginProvider from 'mobile-web/lib/login-provider';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import PhoneNumberDisplayService from 'mobile-web/services/phone-number-display';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  vendor: Vendor;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class GroupOrderStartRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service groupOrder!: GroupOrderService;
  @service phoneNumberDisplay!: PhoneNumberDisplayService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked currentMode = 0;

  // Getters and setters
  get loginProviders(): LoginProvider[] {
    return this.channel.current?.loginProviders ?? [];
  }

  get isOffPremise() {
    return !this.onPremise.isEnabled;
  }

  get requirePhoneNumber() {
    return this.phoneNumberDisplay.phoneNumberIsRequired;
  }

  get userData(): GuestUser | undefined {
    const user = this.session.currentUser;
    return user
      ? pick(user, 'firstName', 'lastName', 'emailAddress', 'contactNumber', 'optIn')
      : undefined;
  }

  get supportsOloLogin(): boolean | undefined {
    return this.channel.settings?.supportsOloLogin;
  }

  get showSignInCreateAccountForm(): boolean {
    return !!this.supportsOloLogin && this.isOffPremise && !this.session.isLoggedIn;
  }

  get showGuestCheckoutForm(): boolean {
    return this.onPremise.isEnabled || this.session.isLoggedIn;
  }

  // Lifecycle methods

  // Other methods

  // Tasks
  submitTask = task(this, async (user: GuestUser): Promise<void> => {
    if (this.session.currentUser && !this.session.isLoggedIn) {
      await this.session.logout();
    }
    if (!this.basket.basket) {
      await this.basket.createBasket(this.args.vendor);
    }
    if (!this.session.currentUser) {
      if (this.isOffPremise && !user.emailAddress) {
        this.error.sendUserMessage({ detail: 'Email address required' });
        return;
      }

      this.onPremise.prepareGuestUser(user);

      const response = await this.store.collectionAction('user', 'guestGrouporderStart', user);

      await this.session.setUserFromPayload(response);
    }

    await this.groupOrder.startGroupOrder();

    this.analytics.trackEvent(AnalyticsEvents.GroupOrderStarted);

    this.router.transitionTo('menu.vendor', this.args.vendor.slug);
  });

  // Actions and helpers
  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterCreateAccountError(error: any) {
    this.error.sendUserMessage(error);
  }

  @action
  externalLogin(provider: LoginProvider) {
    this.session.externalLoginWithNextRoute(provider.slug);
  }

  invalidLogin(reason: string) {
    this.error.sendUserMessage({ detail: reason });
  }

  @action
  offPremiseLogin(email: string, password: string) {
    this.session.internalLogin(
      email,
      password,
      () => this.submitTask.perform(this.userData!),
      buildOloErrorHandler('login-failure', reason => this.invalidLogin(reason))
    );
  }

  @action
  login() {
    this.session.transitionToLogin();
  }

  @action
  async afterCreateAccount() {
    await this.submitTask.perform(this.userData!);
  }

  @action
  async startGroupOrder() {
    if (this.session.isLoggedIn) {
      await this.submitTask.perform(this.userData!);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::GroupOrder::StartRoute': typeof GroupOrderStartRoute;
  }
}
