import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { MediaService } from 'ember-responsive';

import CmsContentService from 'mobile-web/services/cms-content';
import ImageService from 'mobile-web/services/image';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

export default class MenuBanner extends Component<Signature> {
  // Service injections
  @service media!: MediaService;
  @service cmsContent!: CmsContentService;
  @service image!: ImageService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked loadedImage = false;

  // Getters and setters
  get imageSrc(): string | undefined {
    const keyName = this.media.isTablet ? 'imageUriTablet' : 'imageUriMobile';
    return this.cmsContent.getContent('menuBanner')?.[keyName];
  }

  get altText(): string | undefined {
    const keyName = this.media.isTablet ? 'altTextTablet' : 'altTextMobile';
    return this.cmsContent.getContent('menuBanner')?.[keyName];
  }

  get urlDestination(): string | undefined {
    return this.cmsContent.getContent('menuBanner')?.urlDestination;
  }

  get openUrlNewWindow(): boolean {
    return this.cmsContent.getContent('menuBanner')?.urlNewWindow === '1';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleImageLoad() {
    if (this.loadedImage) {
      return;
    }

    this.image
      .load(this.imageSrc)
      .then(() => {
        this.loadedImage = true;
      })
      .catch(() => ({
        /* intentionally ignore errors */
      }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MenuBanner: typeof MenuBanner;
  }
}
