import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import { Dayjs } from 'dayjs';
import IntlService from 'ember-intl/services/intl';

import { Type as TagType } from 'mobile-web/components/tag-list/tag';
import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import { Weekday } from 'mobile-web/lib/time/calendar';
import WeeklySchedule from 'mobile-web/lib/time/weekly-schedule';
import {
  Address as VendorAddress,
  getShortOverrideDesc,
  OverrideReason,
  toggleVendorFavorite,
} from 'mobile-web/models/vendor';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';
import UserFeedback from 'mobile-web/services/user-feedback';

export type Tag = {
  type: TagType;
  content: string;
};

export enum FailureCategory {
  None = 'None',
  Address = 'Address',
  HandoffMode = 'HandoffMode',
  TimeWantedMode = 'TimeWantedMode',
  TimeWanted = 'TimeWanted',
}

const resultCategoryIsAvailable = (category: FailureCategory): boolean =>
  category !== FailureCategory.HandoffMode && category !== FailureCategory.TimeWantedMode;

export type VendorPreCheckErr = { failureCategory: FailureCategory; errorMessage: string };
export type VendorPreCheckInvalidResult = { isValid: false } & VendorPreCheckErr;
export type VendorPreCheckValidResult = { isValid: true };
export type VendorPreCheckResult = VendorPreCheckValidResult | VendorPreCheckInvalidResult;

export default class VendorSearchResultModel extends DS.Model {
  @service intl!: IntlService;
  @service userFeedback!: UserFeedback;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service error!: ErrorService;

  @DS.attr('string')
  name!: string;
  @DS.attr('string')
  slug!: string;
  @DS.attr('string')
  phoneNumber!: string;
  @DS.attr('number')
  utcOffset!: number;
  @DS.attr('number')
  distance?: number;
  @DS.attr('object')
  address!: VendorAddress;
  @DS.attr('object')
  timeWantedSchedule?: WeeklySchedule;
  @DS.attr('boolean')
  canDeliver?: boolean;
  @DS.attr('boolean')
  canPickup?: boolean;
  @DS.attr('boolean')
  isFavorite!: boolean;
  @DS.attr('boolean')
  displayNationalMenu!: boolean;
  @DS.attr('boolean')
  hasOnlineOrdering!: boolean;
  @DS.attr('boolean')
  menuIsReadOnly!: boolean;
  @DS.attr('boolean')
  isAcceptingOrders!: boolean;
  @DS.attr('array')
  locationAttributes!: { name: string }[];
  @DS.attr('string')
  todaysHours!: string;
  @DS.attr('object')
  preCheckResult?: VendorPreCheckResult;
  @DS.attr('object')
  overrideReason?: OverrideReason;

  @not('hasOnlineOrdering')
  isUnavailable!: boolean;

  @computed('locationAttributes')
  get attributes(): Tag[] {
    return this.locationAttributes.map(({ name: content }) => ({ type: 'neutral', content }));
  }

  @computed('preCheckResult.{failureCategory,isValid}')
  get preCheckCategory(): FailureCategory {
    return isNone(this.preCheckResult) || this.preCheckResult.isValid
      ? FailureCategory.None
      : this.preCheckResult.failureCategory;
  }

  @computed('isUnavailable', 'preCheckCategory')
  get available(): boolean {
    return !this.isUnavailable && resultCategoryIsAvailable(this.preCheckCategory);
  }

  tags(day: Dayjs): Tag[] {
    const schedule = this.timeWantedSchedule
      ? WeeklySchedule.toTags(this.timeWantedSchedule, day.format('dddd') as Weekday)
      : [];
    const tags: Tag[] = [...schedule, ...this.attributes];
    if (this.preCheckResult?.hasOwnProperty('isValid') && !this.preCheckResult.isValid) {
      tags.push({ type: 'negative', content: this.preCheckResult.errorMessage });
    }
    if (this.overrideReason) {
      tags.push({
        type: 'negative',
        content: getShortOverrideDesc(this.overrideReason, this.intl),
      });
    }
    return tags;
  }

  @memberAction<void>({ type: 'post', path: 'toggleasfavorite' })
  toggleFavoriteAction!: MemberAction<void>;

  toggleFavorite(): void {
    toggleVendorFavorite.call(this);
  }
}
