import { action } from '@ember/object';
import Component from '@glimmer/component';

import { guids } from 'mobile-web/lib/utilities/guids';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onChange: Action;
  checked: boolean;
  label: string;

  // Optional arguments
  class?: string;
  dataTestSelector?: string;
  disabled?: boolean;
  inputName?: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class UnbrandedCheckbox extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;
  ids = guids(this, 'input');

  // Tracked properties

  // Getters and setters
  get disabled(): boolean {
    return this.args.disabled ?? false;
  }
  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onChange(e: Event) {
    const { checked } = e.currentTarget as HTMLInputElement;
    this.args.onChange(checked);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Unbranded::Checkbox': typeof UnbrandedCheckbox;
  }
}
