import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { multiOrderData, OnPremiseExperience } from 'mobile-web/lib/on-premise';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

export default class GroupOrderJoinRoute extends Route {
  @service groupOrder!: GroupOrderService;
  @service router!: RouterService;
  @service vendor!: VendorService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service store!: DS.Store;

  async beforeModel(transition: Transition) {
    this.session.localGuestUser = this.session.isLoggedIn ? undefined : this.session.localGuestUser;

    if (!this.session.isLoggedIn) {
      this.session.storeCurrentRouteAsNextRoute();
    }

    if (this.groupOrder.hasGroupOrder) {
      const participantJoiningNewGroupOrder =
        this.groupOrder.isParticipantMode &&
        transition.to.queryParams.hasOwnProperty('groupOrderId') &&
        transition.to.queryParams.groupOrderId !== this.groupOrder.groupOrderId;

      if (participantJoiningNewGroupOrder) return;

      const go = await this.store.findRecord('group-order', this.groupOrder.groupOrderId!, {
        reload: true,
      });
      const basket = await this.store.findRecord('basket', go.hostBasketId);
      const vendor = basket ? await basket.vendor : undefined;
      const slug = vendor?.slug;
      if (slug) {
        if (go.currentUserIsHost) {
          await this.groupOrder.joinGroupOrder(vendor.slug, undefined, true);
        }
        this.router.replaceWith('menu.vendor', slug);
      } else {
        this.router.replaceWith('index');
      }
    }
  }

  async model(params: { groupOrderId: string }) {
    const go = await this.store.findRecord('group-order', params.groupOrderId, { reload: true });
    const basket = await this.store.findRecord('basket', go.hostBasketId);
    const vendor = basket ? await basket.vendor : undefined;

    if (go && vendor && basket) {
      if (!this.groupOrder.groupOrderId) this.groupOrder.groupOrderToJoin = go.id;
    } else {
      this.router.replaceWith('index');
      return { slug: '' };
    }

    if (go.currentUserIsHost) {
      // The host needs to be automatically added to the group order
      await this.groupOrder.joinGroupOrder(vendor.slug, undefined, true);
      this.router.replaceWith('menu.vendor', vendor.slug);
    }

    if (basket?.onPremiseDetails?.experienceType === OnPremiseExperience.MultiOrder) {
      this.onPremise.multiOrder = multiOrderData(vendor.slug);
    }

    return { slug: vendor.slug };
  }
}
