import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import DS from 'ember-data';

import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

export default class PayRoute extends Route {
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;
  @service vendor!: VendorService;
  @service onPremise!: OnPremiseService;
  @service store!: DS.Store;

  async model(params: { vendor_slug: string }) {
    this.onPremise.payAtTable = true;

    const vendor = await this.vendor.ensureVendorLoaded(params.vendor_slug);
    return vendor;
  }

  async afterModel(_model: ModelForRoute<this>, transition: Transition) {
    this.analytics.trackEvent(AnalyticsEvents.PayAtTableFindCheckPage);

    const ticketNumber =
      transition.to.queryParams.ticketNumber ?? transition.to.queryParams.ticketnumber;

    const openCheckGuid = this.features.flags['split-check-olo-18091']
      ? transition.to.queryParams.opencheckguid
      : undefined;

    if (ticketNumber) {
      const ticket = this.store.createRecord('ticket', { ticketNumber });
      const transitionToCheckout = await this.onPremise.payAtTableCheckout(ticket, openCheckGuid);
      if (transitionToCheckout) {
        transition.abort(); //do not load current pay route
      }
    }
  }
}
