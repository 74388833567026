import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { hash } from 'rsvp';

import authenticated from 'mobile-web/decorators/authenticated';
import LoyaltyService from 'mobile-web/services/loyalty';
import SessionService from 'mobile-web/services/session';

@authenticated
export default class MyAccountRoute extends Route {
  @service loyalty!: LoyaltyService;
  @service session!: SessionService;
  @service store!: DS.Store;

  async model() {
    const user = this.session.currentUser!; // @authenticated guarantees this
    const memberships = this.loyalty.loadMemberships();
    const schemes = this.store.findAll('loyalty-scheme');
    const billingMemberships = await this.store.query('billing-membership', {});
    await Promise.all(billingMemberships.map(m => m.tryLoadBalance()));

    return hash({ user, memberships, billingMemberships, schemes });
  }
}
