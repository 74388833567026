import { assert } from '@ember/debug';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import { NotificationOrder } from 'mobile-web/components/notifications';
import Data, { isLoaded, isError, isLoading, Loaded } from 'mobile-web/lib/data';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import BasketService from 'mobile-web/services/basket';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import { Notification, NotificationType } from 'mobile-web/services/notifications';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';

import style from './index.m.scss';

export const HEADER_ID = 'olo-header';
export const CART_BUTTON_ID = 'olo-cart-button';

export enum State {
  Default = 'default',
  Home = 'home',
  Checkout = 'checkout',
  GroupOrderParticipantConfirmation = 'participant-confirmation',
}

export type HeaderArguments = {
  isEmbedded: boolean;
  isRestrictedFlow: boolean;
  loggedIn: boolean;
  onLogout: Action;
  pageTitle: string;
  showOffersInbox: boolean;
  signOnAllowed: boolean;
  state: State;
};

export type HeaderModel = Data<HeaderArguments, string>;

interface Args {
  // Required arguments
  model: HeaderModel;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class Header extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service media!: MediaService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service sideMenu!: SideMenuService;
  @service device!: DeviceService;

  // Untracked properties
  headerId = HEADER_ID;
  cartButtonId = CART_BUTTON_ID;
  style = style;

  // Tracked properties

  // Getters and setters
  get isError(): boolean {
    return isError(this.args.model);
  }

  get isLoading(): boolean {
    return isLoading(this.args.model) || this.router.currentRouteName === 'application_loading';
  }

  get modelValue() {
    return (this.args.model as unknown as Loaded<HeaderArguments>).value;
  }

  get showHome(): boolean {
    return (
      isLoaded(this.args.model) &&
      !this.args.model.value.isRestrictedFlow &&
      !this.args.model.value.isEmbedded &&
      this.showLogo &&
      !this.device.isHybrid &&
      !this.onPremise.isEnabled &&
      !this.basket?.basket?.isCallcenterOrder
    );
  }

  get showLogo(): boolean {
    return (
      isError(this.args.model) ||
      (isLoaded(this.args.model) &&
        [State.Default, State.Home, State.Checkout].includes(this.args.model.value.state) &&
        !this.args.model.value.isEmbedded)
    );
  }

  get showRewards(): boolean {
    return (
      isLoaded(this.args.model) &&
      !this.args.model.value.isRestrictedFlow &&
      [State.Default, State.Home].includes(this.args.model.value.state) &&
      this.media.isDesktop &&
      this.router.currentRouteName !== 'menu.vendor.rewards' &&
      !this.sideMenu.isEnabled &&
      !this.basket?.basket?.isCallcenterOrder &&
      !this.groupOrder.hasGroupOrder
    );
  }

  get showAccount(): boolean {
    return (
      isLoaded(this.args.model) &&
      !this.args.model.value.isRestrictedFlow &&
      [State.Default, State.Home].includes(this.args.model.value.state) &&
      this.args.model.value.signOnAllowed &&
      !this.sideMenu.isEnabled &&
      !this.onPremise?.multiOrder?.pastOrders?.length && //will need update for open check ("has open tab")
      !this.basket?.basket?.isCallcenterOrder
    );
  }

  get showBasket(): boolean {
    if (this.onPremise.isPayAtTable) {
      return false;
    }
    return (
      !isLoaded(this.args.model) ||
      ([State.Default, State.Home].includes(this.args.model.value.state) &&
        !this.args.model.value.isRestrictedFlow &&
        !this.basket?.basket?.isCallcenterOrder)
    );
  }

  get showMenu(): boolean {
    const menuRouteNames = [
      'index',
      'vendor-search-results',
      'menu.vendor.index',
      'thank-you',
      'dispatch-status',
    ];

    return (
      this.sideMenu.isEnabled &&
      (this.device.isWeb ||
        (this.device.isHybrid && menuRouteNames.includes(this.router.currentRouteName)))
    );
  }

  get showBack(): boolean {
    return (
      this.sideMenu.isEnabled && !this.showMenu // this ensures will only happen on hybrid device
    );
  }

  get showNotifications(): boolean {
    return !this.media.isMobile;
  }

  get notificationsClass(): string {
    return this.style.notifications;
  }

  get notificationsOrder(): NotificationOrder {
    return NotificationOrder.Descending;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    assert('`model` is required', isSome(this.args.model));
  }

  // Other methods
  notificationsFilter(notification: Notification) {
    switch (notification.type) {
      case NotificationType.ProductRemoved:
      case NotificationType.ProductUpdated:
      case NotificationType.UpsellAdded:
        return false;
      default:
        return true;
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Header: typeof Header;
  }
}
