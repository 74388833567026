import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import { SaveFavorite } from 'mobile-web/components/save-favorite-modal';
import { Mode } from 'mobile-web/components/sign-in-create-account-form';
import dayjs from 'mobile-web/lib/dayjs';
import { handoffMessageParts } from 'mobile-web/lib/order';
import { DISPATCH_KEY } from 'mobile-web/lib/order-criteria';
import { isFunction } from 'mobile-web/lib/utilities/_';
import Order from 'mobile-web/models/order';
import Vendor from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import MwcIntl from 'mobile-web/services/mwc-intl';
import ReorderService from 'mobile-web/services/reorder';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

export enum UserOrderEvent {
  CheckIn = 'checkin',
  Arrival = 'arrival',
}

interface Args {
  // Required arguments
  order: Order;

  // Optional arguments
  showTracking?: boolean;
  allowModify?: boolean;
  onUserOrderEvent?: (orderGuid: string, orderHash: string, event: 'arrival' | 'checkin') => void;
  onCancelOrder?: Action;
  saveFavorite?: SaveFavorite;
  allowReorder?: boolean;
  showSocialIntegration?: boolean;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class PostCheckout extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service mwcIntl!: MwcIntl;
  @service reorder!: ReorderService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service features!: FeaturesService;
  @service bootstrap!: BootstrapService;
  @service storage!: StorageService;
  @service media!: MediaService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get showTracking() {
    return this.args.showTracking ?? true;
  }

  get allowModify() {
    return this.args.allowModify ?? true;
  }

  get isLoggedIn(): boolean {
    return this.session.isLoggedIn;
  }

  get isRestrictedFlow(): boolean {
    return this.session.isRestrictedFlow;
  }

  get vendor(): Vendor {
    return this.args.order.vendor;
  }

  get isMultiConcept(): boolean {
    return (
      this.features.flags.VBGK_MENU_AGGREGATION &&
      this.args.order.basketProducts.any(b => !!b.brandName)
    );
  }

  get isDelivery(): boolean {
    return this.args.order.isDelivery;
  }

  get isDispatch(): boolean {
    return this.args.order.deliveryMode === DISPATCH_KEY;
  }

  get isPendingManualFire() {
    // Manual fire automatically triggered by arrival
    return this.args.order.canManualFire && !this.args.order.canSupportArrival;
  }

  get onManualFire() {
    return this.isPendingManualFire && this.args.onUserOrderEvent
      ? this.args.onUserOrderEvent
      : undefined;
  }

  get onArrival() {
    return this.args.order.canSupportArrival && this.args.onUserOrderEvent
      ? this.args.onUserOrderEvent
      : undefined;
  }

  get substitutions() {
    const url = this.channel.settings?.fullSiteUrl ?? '';
    const orderGuid = this.args.order.orderGuid;
    const hash = encodeURIComponent(this.args.order.orderHash);

    return {
      ManualFireUrl: `https://${url}/order/checkin/${orderGuid}?hash=${hash}`,
    };
  }

  get handoffDetailsHeading() {
    return this.isDelivery ? 'Delivery Details' : 'Pickup Details';
  }

  get canChangeOrder() {
    return (
      !this.isRestrictedFlow &&
      this.isLoggedIn &&
      ((this.args.order.canCancel && isFunction(this.args.onCancelOrder)) ||
        (this.allowModify && this.args.order.canModify))
    );
  }

  get onCancelOrder() {
    return this.canChangeOrder ? this.args.onCancelOrder : undefined;
  }

  get isOloAuth(): boolean {
    return (
      !!this.bootstrap.data?.isOloAuthLogin || !!this.storage.orderSubmission?.createOloAccount
    );
  }

  get showCreateAccountButton() {
    return !this.isLoggedIn && this.session.signOnAllowed && !this.isOloAuth;
  }

  get isPastOrder(): boolean {
    return this.args.order.isPastOrder;
  }

  get handoffMessageParts() {
    let dateTime: string;
    if (this.args.order.timeReadyUtc && this.args.order.vendor.timeZoneId) {
      dateTime = this.mwcIntl.checkoutRelativeDateTime(
        this.args.order.timeReadyUtc,
        this.args.order.vendor.timeZoneId
      );
    } else {
      const timeReady = dayjs(this.args.order.timeReadyLocal);
      const relativeDate = this.mwcIntl.checkoutHandoffRelativeDate(timeReady);
      dateTime = this.mwcIntl.whitespaceNormalizedTranslate('mwc.postCheckout.timeWanted', {
        date: relativeDate,
        time: timeReady,
      });
    }

    return handoffMessageParts(this.args.order, dateTime);
  }

  get showReorder(): boolean {
    return this.args.allowReorder ?? false;
  }

  get showDispatchingTracking(): boolean {
    return this.showTracking && this.isDispatch;
  }

  get showCurrentOrderButtons(): boolean {
    return (
      !this.isPastOrder &&
      (this.showDispatchingTracking ||
        !this.isDelivery ||
        this.isPendingManualFire ||
        this.args.order.canSupportArrival)
    );
  }

  get showButtonContainer(): boolean {
    return this.showReorder || this.showCurrentOrderButtons;
  }

  get showFeedbackAboveSummary(): boolean {
    return !this.media.isMobile;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  login() {
    this.router.transitionTo('login');
  }

  @action
  upgradeAccount() {
    if (this.session.internalSignOnAllowed) {
      this.router.transitionTo('login', {
        queryParams: { mode: Mode.CreateAccount, upgrade: true },
      });
    } else if (this.session.hasLoginProvider) {
      const provider = this.session.loginProviders![0];
      this.session.externalLoginWithNextRoute(provider.slug);
    }
  }

  @action
  doReorder() {
    this.reorder.reorder(this.args.order);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PostCheckout: typeof PostCheckout;
  }
}
