import { service } from '@ember/service';
import DS from 'ember-data';
import ModelRegistry from 'ember-data/types/registries/model';

import ApplicationAdapter from 'mobile-web/adapters/application';
import { isBoolean } from 'mobile-web/lib/utilities/_';
import BootstrapService from 'mobile-web/services/bootstrap';

export default class BootstrapDataAdapter extends ApplicationAdapter.extend({}) {
  @service bootstrap!: BootstrapService;

  handleResponse(
    ...parameters: Parameters<DS.RESTAdapter['handleResponse']>
  ): ReturnType<DS.RESTAdapter['handleResponse']> {
    try {
      const headers = parameters[1] as UnknownObject;
      const oloIpGeolocate = headers['olo-ip-geolocate'];

      if (typeof oloIpGeolocate === 'string') {
        this.bootstrap.cloudflareHeader = oloIpGeolocate;
        this.bootstrap.ipGeolocatedZip = this.parseIpGeolocatedZip(oloIpGeolocate);
      }
    } catch {
      /**
       * Purposefully do nothing. Successfully fetching bootstrap data is
       * absolutely critical to initializing Serve. However, getting the user's
       * geolocated location is only a nice-to-have. So if something goes wrong
       * here, we can ignore it. But we absolutely cannot disrupt Serve's
       * initialization process.
       */
    }
    return super.handleResponse(...parameters);
  }

  parseIpGeolocatedZip(ipGeoData: string): string | undefined {
    const ipGeolocatedZipData = ipGeoData?.split(';');
    const zipCodeGeoPart = ipGeolocatedZipData?.find(geoPart => geoPart.includes('zipcode'));
    const zipCode = zipCodeGeoPart?.split('=')[1];

    return zipCode;
  }

  urlForFindRecord<K extends keyof ModelRegistry>(
    id: string,
    modelName: K,
    snapshot: DS.Snapshot<K>
  ): string {
    const baseUrl = super.urlForFindRecord(id, modelName, snapshot);
    const includeAnalytics = isBoolean(window.Olo.includeAnalytics)
      ? window.Olo.includeAnalytics
      : false;
    return `${baseUrl}?includeAnalytics=${includeAnalytics}`;
  }
}
