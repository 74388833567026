import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';

import { OrderCriteria } from 'mobile-web/lib/order-criteria';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import CmsContentService from 'mobile-web/services/cms-content';
import ContentService from 'mobile-web/services/content';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';

import style from './index.m.scss';

interface Args {
  // Required arguments
  vendor: Vendor;

  // Optional arguments
  showInfoModal?: boolean;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class VendorFrame extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service cmsContent!: CmsContentService;
  @service content!: ContentService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service onPremise!: OnPremiseService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service sideMenu!: SideMenuService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get hasBasketProducts(): boolean {
    return (this.basket.basket?.basketProducts.length ?? 0) > 0;
  }

  get criteria(): OrderCriteria {
    return this.orderCriteria.criteria;
  }

  get safeCustomerFacingMessage(): SafeString {
    return this.content.dangerouslySanitizeTrustedContent(
      this.args.vendor.settings.customerFacingMessage
    );
  }

  get showMenuBanner(): boolean {
    if (this.media.isTablet && this.cmsContent.getContent('menuBanner')?.imageUriTablet) {
      return true;
    } else if (this.media.isMobile && this.cmsContent.getContent('menuBanner')?.imageUriMobile) {
      return true;
    }
    return false;
  }

  get showRewards(): boolean {
    return (this.sideMenu.isEnabled || !this.media.isDesktop) && !this.groupOrder.hasGroupOrder;
  }

  get showGroupOrderBanner(): boolean {
    return (
      this.groupOrder.isEnabled && this.groupOrder.hasGroupOrder && !this.onPremise.hasOpenCheck
    );
  }

  get showOrderCriteria(): boolean {
    return (
      this.onPremise.showTableNumber ||
      !this.onPremise.isEnabled ||
      this.onPremise.isOpenCheckSubsequentRound ||
      this.onPremise.pastMultiordersExist
    );
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  openOrderCriteriaModal() {
    this.orderCriteria.openModal();
  }

  @action
  toggleFavorite() {
    this.args.vendor.toggleFavorite();
  }

  @action
  goToMultiOrderConfirmation() {
    if (this.onPremise.multiOrder) {
      this.analytics.trackEvent(AnalyticsEvents.VendorPageOrdersButton);
    }
    this.onPremise.goToMultiOrderConfirmation();
  }

  @action
  goToCloseCheck() {
    this.analytics.trackEvent(AnalyticsEvents.OpenCheckFinishPay, () => ({
      [AnalyticsProperties.OpenCheckRoundsOrdered]: this.onPremise.openCheckRoundsOrdered ?? 0,
    }));
    this.onPremise.goToCloseOpenCheck();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorFrame: typeof VendorFrame;
  }
}
