import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { isOk } from 'mobile-web/lib/result';
import { PASSWORD_MINLENGTH } from 'mobile-web/lib/security';
import Validation, { ValidationResult, ValidationConfig } from 'mobile-web/lib/validation';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChallengeService from 'mobile-web/services/challenge';
import ErrorService from 'mobile-web/services/error';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import WindowService from 'mobile-web/services/window';

import style from './index.m.scss';

interface Args {
  // Required arguments
  id: string;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class ResetPasswordRoute extends Component<Signature> {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service challenge!: ChallengeService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service window!: WindowService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked newPassword = '';
  @tracked confirmPassword = '';
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<ResetPasswordRoute> {
    return {
      bindings: [
        {
          targetProp: 'newPassword',
          ruleName: 'minLength',
          message: this.intl.t('mwc.password.minLength', { length: PASSWORD_MINLENGTH }),
        },
        {
          targetProp: 'confirmPassword',
          ruleName: 'match',
          match: 'newPassword',
          message: this.intl.t('mwc.changePassword.errors.passwordsMatch'),
        },
      ],
    };
  }

  get isSubmitDisabled(): boolean {
    return isEmpty(this.newPassword) || isEmpty(this.confirmPassword);
  }

  // Lifecycle methods

  // Other methods

  // Tasks
  @task *resetPasswordTask(): TaskGenerator<void> {
    this.validationResult = Validation.validate(this, this.validationConfig);
    if (isOk(this.validationResult)) {
      taskFor(this.challenge.request).perform(
        async () => {
          await this.store.collectionAction('user', 'resetPassword', {
            id: this.args.id,
            newPassword: this.newPassword,
            confirmNewPassword: this.confirmPassword,
          });

          this.userFeedback.add({
            type: Type.Positive,
            title: this.intl.t('mwc.resetPassword.successTitle'),
            message: this.intl.t('mwc.resetPassword.successMessage'),
          });

          if (this.bootstrap.shouldRedirectToCustomSite) {
            this.window.location().replace(this.bootstrap.customSiteUrl);
            return;
          }

          this.router.transitionTo('login');
        },
        e => {
          this.error.reportError(e);
        }
      );
    }
  }

  // Actions and helpers
  @action
  submit(e: Event) {
    e.preventDefault();

    taskFor(this.resetPasswordTask).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::ResetPasswordRoute': typeof ResetPasswordRoute;
  }
}
