import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { vendorTime } from 'mobile-web/helpers/vendor-time';
import dayjs from 'mobile-web/lib/dayjs';
import Address from 'mobile-web/lib/location/address';
import Vendor from 'mobile-web/models/vendor';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onClose: Action;
  vendor: Vendor;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class VendorInfoModal extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service channel!: ChannelService;
  @service device!: DeviceService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get currentDate(): string {
    return vendorTime(dayjs().utc(), this.args.vendor).format(
      this.intl.t('mwc.vendorInfoModal.currentDayFormat')
    );
  }

  get mapsUrl() {
    return Address.toMapsApplicationLink(this.args.vendor.address, this.device.isIOS);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorInfoModal: typeof VendorInfoModal;
  }
}
