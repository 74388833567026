export default {
  "root": "_root_jmq9tu",
  "newButtonRoot": "_newButtonRoot_jmq9tu",
  "button": "_button_jmq9tu",
  "newButton": "_newButton_jmq9tu",
  "newButtonText": "_newButtonText_jmq9tu",
  "buttonContents": "_buttonContents_jmq9tu",
  "editButton": "_editButton_jmq9tu",
  "value": "_value_jmq9tu",
  "values": "_values_jmq9tu",
  "editIcon": "_editIcon_jmq9tu"
};
