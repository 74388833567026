import { inject as service } from '@ember/service';

import PageTitleHelper from 'ember-page-title/helpers/page-title';

import ChannelService from 'mobile-web/services/channel';
import { SEPARATOR, SUFFIX } from 'mobile-web/services/page-title';

interface NamedArgs {
  top?: boolean;
  noSuffix?: boolean;
}

/**
 * A thin wrapper around the page-title helper from ember-page-title.
 *
 * The types are kind of gross. ember-page-title (EPT) doesn't provide its own
 * types, so we wrote our own. And because we're basically trying to extend the
 * EPT API, we had to write the signature for EPT as a generic, which lets us
 * define additional named args.
 */
export default class OloPageTitleHelper extends PageTitleHelper<NamedArgs> {
  @service channel!: ChannelService;

  compute(params: string[], hash: NamedArgs): string {
    let newParams = hash.top
      ? [this.channel.current!.name, SUFFIX]
      : [this.channel.current!.name, SEPARATOR, ...params];

    if (!hash.top && !hash.noSuffix) {
      newParams.push(SUFFIX);
    }

    newParams = newParams.filter(p => !!p).map(p => p.replace(/%separator%/gi, SEPARATOR.trim()));

    return super.compute(newParams, {
      ...hash,
      prepend: false,
      replace: true,
      separator: SEPARATOR,
    }) as string;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'page-title': typeof OloPageTitleHelper;
  }
}
