import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { WithBoundArgs } from '@glint/template';
import IntlService from 'ember-intl/services/intl';

import CardButton from 'mobile-web/components/card/button';
import { HeadingLevel } from 'mobile-web/components/heading';
import { Dayjs } from 'mobile-web/lib/dayjs';
import TZ from 'mobile-web/lib/time/timezone';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

type Args = RequireAtLeastOne<
  {
    // Required arguments
    model: VendorSearchResultModel;
    day: Dayjs;

    // Optional arguments
    destRoute?: string;
    buttons?: {
      one: WithBoundArgs<typeof CardButton, 'label' | 'onClick'>;
      two?: WithBoundArgs<typeof CardButton, 'label' | 'onClick'>;
    };
    headingLevel?: HeadingLevel;
  },
  'destRoute' | 'buttons'
>;

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class VendorCard extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service channel!: ChannelService;

  // Untracked properties
  ids = guids(this, 'heading');
  style = style;

  // Tracked properties

  // Getters and setters
  get headingLevel(): HeadingLevel {
    return this.args.headingLevel ?? 2;
  }

  get orderLabelText() {
    return this.args.model.hasOnlineOrdering
      ? this.intl.t('mwc.vendorCard.beginOrderText')
      : this.intl.t('mwc.vendorCard.viewMenuText');
  }

  get tags() {
    const { displayNationalMenu, hasOnlineOrdering, name, utcOffset, isAcceptingOrders } =
      this.args.model;
    const value = [...this.args.model.tags(this.args.day)];

    if (utcOffset !== TZ.localOffset()) {
      value.push({
        type: 'cautionary',
        content: TZ.offsetDifferenceMessage(name, utcOffset),
      });
    }

    if ((displayNationalMenu && !hasOnlineOrdering) || !isAcceptingOrders) {
      value.push({
        type: 'cautionary',
        content: this.intl.t('mwc.vendorCard.noOnlineOrdering'),
      });
    }
    return value;
  }

  get hasDistance() {
    return isSome(this.args.model.distance);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onToggleFavorite() {
    this.args.model.toggleFavorite();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorCard: typeof VendorCard;
  }
}
