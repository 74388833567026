export default {
  "modal": "_modal_55j9mj",
  "modalHeader": "_modalHeader_55j9mj",
  "modalHeaderIcon": "_modalHeaderIcon_55j9mj",
  "modalBody": "_modalBody_55j9mj",
  "descriptionText": "_descriptionText_55j9mj",
  "qrCodeButtonContainer": "_qrCodeButtonContainer_55j9mj",
  "shareLinkButton": "_shareLinkButton_55j9mj",
  "shareLinkButtonContent": "_shareLinkButtonContent_55j9mj",
  "shareLinkButtonIcon": "_shareLinkButtonIcon_55j9mj",
  "shareableUrlText": "_shareableUrlText_55j9mj",
  "qrCodeLinkContainer": "_qrCodeLinkContainer_55j9mj",
  "copyLinkButton": "_copyLinkButton_55j9mj",
  "copyLinkButtonIcon": "_copyLinkButtonIcon_55j9mj",
  "copyLinkButtonContent": "_copyLinkButtonContent_55j9mj"
};
