import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import ChannelService from 'mobile-web/services/channel';
import CmsContentService from 'mobile-web/services/cms-content';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

interface Args {
  // Required arguments
  altText: ContentString;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class HeroImage extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service cmsContent!: CmsContentService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get heroImage(): string {
    return this.cmsContent.getContent('heroImage')?.imageUri ?? this.channel.heroImageUrl;
  }

  get heroImageAlt(): ContentString | undefined {
    return this.cmsContent.getContent('heroImage')?.altText ?? this.args.altText;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeroImage: typeof HeroImage;
  }
}
