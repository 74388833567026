import { isEmpty } from '@ember/utils';

import pick from 'lodash.pick';

import freeze from 'mobile-web/lib/utilities/freeze';
import { Address as VendorAddress } from 'mobile-web/models/vendor';

import isSome from '../utilities/is-some';

export type Coords = {
  latitude: number;
  longitude: number;
};

export type Address = {
  streetAddress: string;
  building?: string;
  city: string;
  zipCode: string;
};

export type SavedAddress = Address & { id?: string };

export type AddressFields = keyof Address;

// prettier-ignore
export const isValid = (v?: AnyObject): v is Address =>
  !isEmpty(v?.streetAddress) &&
  !isEmpty(v?.city) &&
  !isEmpty(v?.zipCode);

export const EMPTY: Address = freeze({
  streetAddress: '',
  building: '',
  city: '',
  zipCode: '',
});
export function makeEmpty(): Address {
  return Object.assign({}, EMPTY);
}

export function isAddress(a: Address | VendorAddress | undefined): a is Address {
  return isSome(a) && 'zipCode' in a;
}

export function isVendorAddress(a: Address | VendorAddress | undefined): a is VendorAddress {
  return isSome(a) && 'postalCode' in a;
}

export function toLabel(address: Address | VendorAddress): string {
  // If the street address is empty, we assume everything else is empty.
  if (!address.streetAddress) {
    return '';
  }
  let label = address.streetAddress + ', ';
  if (isAddress(address) && address.building) {
    label += address.building + ', ';
  } else if (isVendorAddress(address) && address.streetAddress2) {
    label += address.streetAddress2 + ', ';
  }
  label += `${address.city} ${isAddress(address) ? address.zipCode : address.postalCode}`;
  return label;
}

const MAPS_WEB_HREF_BASE = 'https://maps.google.com/?q=';
// https://stackoverflow.com/a/19464433/4326495
const IOS_MAPS_NATIVE_HREF_BASE = 'https://maps.apple.com/?q=';
export function toMapsApplicationLink(
  address: Address | VendorAddress | string,
  isIOS: boolean
): string {
  let query!: string;
  if (typeof address === 'string') {
    query = address;
  } else {
    const props = isAddress(address)
      ? pick<Address, keyof Address>(address, 'streetAddress', 'building', 'city', 'zipCode')
      : pick<VendorAddress, keyof VendorAddress>(
          address,
          'streetAddress',
          'streetAddress2',
          'city',
          'state',
          'postalCode',
          'country'
        );
    query = Object.values(props)
      .filter(v => !isEmpty(v))
      .join(' ');
  }
  const base = isIOS ? IOS_MAPS_NATIVE_HREF_BASE : MAPS_WEB_HREF_BASE;
  return base + encodeURIComponent(query);
}

export function distanceBetweenCoords(start: Coords, end: Coords): number {
  if (!(start && end && start.latitude && start.longitude && end.latitude && end.longitude)) {
    throw new Error('Tried to get coordinate distance without providing coordinates');
  }
  // Using Haversine formula
  // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
  const p = 0.017453292519943295; // Math.PI / 180
  const r = 7917; // 2 * R; R (earth radius) = 6371 km -> 3959 miles
  const c = Math.cos;
  const a =
    0.5 -
    c((end.latitude - start.latitude) * p) / 2 +
    (c(start.latitude * p) * c(end.latitude * p) * (1 - c((end.longitude - start.longitude) * p))) /
      2;
  return Math.ceil(r * Math.asin(Math.sqrt(a)) * 10) / 10;
}

export function savedAddressToLabel(deliveryAddress: SavedAddress | undefined): string {
  return isAddress(deliveryAddress) ? toLabel(deliveryAddress) : '';
}

export const Address = {
  EMPTY,
  makeEmpty,
  toLabel,
  toMapsApplicationLink,
  isValid,
};

export default Address;
