import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { Variant } from 'mobile-web/components/button';
import { ErrorCategory } from 'mobile-web/lib/errors';
import { getHandoffLabel, getTimeWantedTypeLabel, isAdvance } from 'mobile-web/lib/order-criteria';
import { createSelfInvokingCallback } from 'mobile-web/lib/self-invoking-callback';
import { noop } from 'mobile-web/lib/utilities/_';
import { classes } from 'mobile-web/lib/utilities/classes';
import isSome from 'mobile-web/lib/utilities/is-some';
import { roundDecimals } from 'mobile-web/lib/utilities/math';
import { ValidateResponse } from 'mobile-web/models/basket';
import BasketProductModel from 'mobile-web/models/basket-product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ErrorService, { DEFAULT_TITLE, errorForUser } from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService, { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';
import MwcIntl from 'mobile-web/services/mwc-intl';
import NotificationsService, {
  Notification,
  NotificationType,
} from 'mobile-web/services/notifications';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

const CART_ID = 'OLO_CART';

interface Args {
  // Required arguments

  // Optional arguments
  fastTapBasket?: Action;
}

interface Signature {
  Args: Args;
}

export default class Cart extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service mwcIntl!: MwcIntl;
  @service onPremise!: OnPremiseService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service vendor!: VendorService;
  @service storage!: StorageService;
  @service notifications!: NotificationsService;

  // Untracked properties
  cartId = CART_ID;
  style = style;
  private tapClearTimer?: number = undefined;
  private tapCount = 0;

  // Tracked properties
  @tracked cartElement?: HTMLElement;
  @tracked debugInput?: HTMLInputElement;
  @tracked isGroupOrderModalOpen = false;

  // Getters and setters
  get outsideVendorMenu() {
    return isSome(this.vendor.vendor) && this.router.currentRouteName.indexOf('menu') === -1;
  }

  get showProducts() {
    return (
      !this.basket.orderBasketGroup.isRunning &&
      (this.onPremise.openCheck
        ? this.basket.openCheckUnsentBasketProducts.length > 0
        : this.groupOrder.isParticipantMode
        ? this.groupOrder.currentUserProducts
        : this.basket.basketProducts)
    );
  }

  get defaultProducts() {
    if (this.onPremise.openCheck) {
      return this.basket.openCheckUnsentBasketProducts;
    }

    return this.groupOrder.isHostMode
      ? this.groupBasketProducts[this.groupOrder.currentUserName]
      : this.basket.displayProducts;
  }

  get emptyCartClass() {
    return classes(style.emptyContainer, {
      [style.hasGroupOrder]: this.groupOrder.hasGroupOrder && this.groupOrder.isHostMode,
      [style.hasOpenCheck]: this.onPremise.hasOpenCheck,
    });
  }

  get handoffMinimum(): number {
    const basket = this.basket.basket;
    if (isSome(basket)) {
      const minimumDelivery = basket.vendor.get('minimumDeliveryOrder') ?? 0;
      const minimumPickup = basket.vendor.get('minimumPickupOrder') ?? 0;
      const { handoffMode } = basket;
      // Dispatch does not use vendor minimumDeliveryOrder.
      if (handoffMode === 'Dispatch') {
        return 0;
      }
      if (handoffMode === 'Delivery') {
        return minimumDelivery;
      }
      return minimumPickup;
    }
    return 0;
  }

  get remainingAmount(): number {
    const basket = this.basket.basket;
    if (isSome(basket) && this.handoffMinimum > basket.subTotal) {
      return roundDecimals(this.handoffMinimum - basket.subTotal);
    }
    return 0;
  }

  get orderTimeLabel(): string {
    if (!this.orderCriteria.basketOrderCriteria || this.onPremise.isEnabled) {
      return '';
    }
    const criteria = this.orderCriteria.basketOrderCriteria;
    if (isAdvance(criteria) && criteria.timeWanted) {
      let timeLabel: string;
      const timeZone = this.basket.basket?.vendor?.get('timeZoneId');
      if (timeZone) {
        timeLabel = this.mwcIntl.vendorRelativeDateTime(criteria.timeWanted.toDate(), timeZone);
      } else {
        timeLabel = this.mwcIntl.relativeDateTime(criteria.timeWanted);
      }
      return timeLabel;
    }
    if (criteria.timeWantedType === 'Immediate') {
      if (
        this.features.flags['abtest-asap-scheduled-dictionary-default'] === 'Now:Later' ||
        this.features.flags['abtest-asap-scheduled-dictionary-default'] === 'Next Available:Later'
      ) {
        return this.intl.t('mwc.cart.immediateLabel', {
          description: `${getHandoffLabel(
            criteria.handoffMode,
            this.store
          )} ${getTimeWantedTypeLabel(criteria.timeWantedType, this.store)}`,
          leadTime: this.basket.basket!.leadTimeEstimate || 0,
        });
      }
      return this.intl.t('mwc.cart.immediateLabel', {
        description: getTimeWantedTypeLabel(criteria.timeWantedType, this.store),
        leadTime: this.basket.basket!.leadTimeEstimate || 0,
      });
    }
    return getTimeWantedTypeLabel(criteria.timeWantedType, this.store);
  }

  get showMinimum(): boolean {
    return this.remainingAmount > 0;
  }

  get showTotals(): boolean {
    return !this.groupOrder.isParticipantMode;
  }

  get isCheckoutDisabled(): boolean {
    return this.showMinimum || this.basket.hasOnlySingleUseProducts;
  }

  get groupOrderParticipants(): string[] {
    if (!this.groupOrder.isHostMode) {
      return [];
    }

    return Object.keys(this.groupBasketProducts).filter(x => x !== this.groupOrder.hostDisplayName);
  }

  //same as groupOrder.currentUserProducts except host is not included
  get groupBasketProducts(): Record<string, BasketProductModel[]> {
    const products = this.basket.basketProducts;

    const partProdsMap: Record<string, BasketProductModel[]> = {};
    products.forEach(x => {
      const key = x.recipientName;
      const prodCollection = partProdsMap[key];
      if (!prodCollection) {
        partProdsMap[key] = [x];
      } else {
        prodCollection.push(x);
      }
    });

    return partProdsMap;
  }

  get showAggregatedUI(): boolean {
    return (
      this.features.flags.VBGK_MENU_AGGREGATION &&
      this.vendor.isMultiConcept &&
      this.features.flags['abtest-vbgk-concept-carousel-olo-38237']
    );
  }

  get showOrderMoreLabel(): boolean {
    return !!this.onPremise.multiOrder?.pastOrders || this.onPremise.hasOpenCheck;
  }

  // Other methods
  async goToCheckout() {
    await this.groupOrder.lockGroupOrder(true);
    this.analytics.trackEvent(AnalyticsEvents.ProceedToCheckout, undefined, { bucket: 'all' });

    this.router.transitionTo('checkout.auth');
  }

  notificationsFilter(notification: Notification) {
    switch (notification.type) {
      case NotificationType.ProductRemoved:
      case NotificationType.ProductUpdated:
      case NotificationType.UpsellAdded:
      case NotificationType.SendOpenCheckBasket:
        return true;
      default:
        return false;
    }
  }

  // Tasks
  proceedToCheckoutTask = taskFor(this.proceedToCheckoutTaskInstance);
  @task *proceedToCheckoutTaskInstance(): TaskGenerator<void> {
    try {
      if (this.onPremise.multiOrder) {
        yield this.basket.onPremise.setBasketOnPremiseDetails();
      }

      if (this.basket.openCheckUnsentBasketProducts.length) {
        this.router.transitionTo('open-check.review');
        return;
      }

      const response: ValidateResponse = yield this.basket.basket!.validate();
      if (response.success) {
        // Trigger the checkout event. Pass the basket data and a callback to
        // be invoked when tracking checkout is complete. Create a self-invoking
        // callback that will run after 750ms if it isn't called before then.
        // The use-case for this is GTM or another tag library needing to complete
        // emitting the `checkout` event _before_ we navigate to the checkout page.
        if (this.globalEvents.has(GlobalEventName.Checkout)) {
          this.globalEvents.trigger(
            GlobalEventName.Checkout,
            this.basket.basket!.serializeForGlobalData(),
            createSelfInvokingCallback(() => this.goToCheckout(), 750)
          );
        } else {
          this.goToCheckout();
        }
      }
    } catch (e) {
      if (errorForUser(e) && e.errors[0].category === ErrorCategory.CouponRequirementsNotMet) {
        const message = this.intl.t('mwc.cart.invalidCoupon.error', { error: e.errors[0].message });

        this.userFeedback.add({
          type: Type.Negative,
          title: DEFAULT_TITLE,
          message,
          actions: [
            {
              label: this.intl.t('mwc.cart.invalidCoupon.keep'),
              fn: noop,
              variant: Variant.Minor,
              cancelAction: true,
            },
            {
              label: this.intl.t('mwc.cart.invalidCoupon.remove'),
              fn: async () => {
                await this.basket.basket?.removeCoupon();
                this.checkout();
              },
              variant: Variant.Main,
            },
          ],
        });
      } else {
        this.error.reportError(e);
      }
    }
  }

  // Actions and helpers
  @action
  async checkout() {
    if (this.isCheckoutDisabled) {
      return;
    }

    if (this.groupOrder.isLocked) {
      this.groupOrder.showGroupOrderLockedModal();
      return;
    }

    if (this.groupOrder.isParticipantMode) {
      await this.groupOrder.groupOrder?.updateParticipantStatus({
        participantName: this.groupOrder.currentUserName,
        status: GroupOrderParticipantStatus.Submitted,
      });
      this.router.transitionTo('group-order.participant-confirmation');
    } else {
      if (this.groupOrder.hasGroupOrder && this.groupOrder.hasParticipantsInProgress) {
        this.openModal();
      } else {
        this.proceedToCheckoutTask.perform();
      }
    }
  }

  @action
  groupOrderModalConfirm() {
    this.analytics.trackEvent(AnalyticsEvents.GroupOrderHostProceededParticipantsInProgress);
    this.proceedToCheckoutTask.perform();
  }

  @action
  openModal() {
    this.isGroupOrderModalOpen = true;
  }

  @action
  addMoreItems() {
    this.basket.close();
    this.router.transitionTo('menu.vendor', this.basket.basket!.vendor.get('slug')!);
  }

  @action
  startOrder() {
    this.basket.close();
    if (this.router.currentRouteName === 'thank-you') {
      this.router.transitionTo('menu.vendor', this.vendor.vendorSlug!);
    }
  }

  @action
  inviteToGroupOrder() {
    this.basket.close();
    this.groupOrder.showInviteModal = true;
    this.analytics.trackEvent(AnalyticsEvents.GroupOrderInviteOthersButton);
  }

  @action
  clearTapCount() {
    this.tapCount = 0;
  }

  @action
  click() {
    clearTimeout(this.tapClearTimer);
    this.tapCount++;
    if (this.tapCount >= 10) {
      // reveal Launch Darkly user key
      if (this.features.userKey) {
        // eslint-disable-next-line no-console
        console.log('%c LD Key: ', 'color:yellowgreen', this.features.userKey);
      }

      // reveal basket guid
      const guid = this.basket.basket?.guid;
      if (guid !== undefined) {
        this.debugInput?.select();
        document.execCommand('copy');
        // eslint-disable-next-line no-alert
        alert(`Basket ID (${guid}) has been copied to clipboard!`);
      }
      this.clearTapCount();
    } else {
      this.tapClearTimer = setTimeout(this.clearTapCount, 1000) as unknown as number;
    }
  }

  @action
  viewPreviousOrders() {
    if (!this.onPremise.multiOrder?.pastOrders || this.router.currentRouteName === 'thank-you') {
      this.basket.close();
      return;
    }

    this.onPremise.goToMultiOrderConfirmation();
  }

  @action
  goToCloseCheck() {
    this.analytics.trackEvent(AnalyticsEvents.OpenCheckFinishPay, () => ({
      [AnalyticsProperties.OpenCheckRoundsOrdered]: this.onPremise.openCheckRoundsOrdered ?? 0,
    }));
    this.onPremise.goToCloseOpenCheck();
  }

  @action
  onStatusModalClose() {
    this.isGroupOrderModalOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Cart: typeof Cart;
  }
}
