import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import TicketModel from 'mobile-web/models/ticket';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  ticket?: TicketModel;
  showSubtotal?: boolean;
  itemLimit?: number;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class OnPremiseTicketContents extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get itemLimit() {
    return this.args.itemLimit !== undefined ? this.args.itemLimit : this.args.ticket?.items.length;
  }

  get hiddenItemAmount() {
    return this.args.itemLimit !== undefined && this.args.ticket
      ? this.args.ticket?.items.length - this.args.itemLimit
      : 0;
  }

  get moreItemsMessage() {
    return this.intl.t('mwc.paySelect.moreItemsMessage', {
      itemAmount: this.hiddenItemAmount,
      plural: this.hiddenItemAmount > 1 ? 's' : '',
    });
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OnPremise::TicketContents': typeof OnPremiseTicketContents;
  }
}
