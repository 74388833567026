import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { ComponentLike } from '@glint/template';

import { ModalSectionSignature } from 'mobile-web/components/modal-section';
import { safeNext } from 'mobile-web/lib/runloop';
import ModalService from 'mobile-web/services/modal';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onClose: () => void;
  title: string;

  // Optional arguments
  hideClose?: boolean;
  clickOutsideToClose?: boolean;
  buttons?: 'yield';
  error?: string;
  testSelector?: string;
  wrapperClass?: string;
  bodyDidInsert?: (el: HTMLElement) => void;
  modalClass?: string;
}

export interface ModalSignature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    header: [];
    errorAction: [];
    default: [
      {
        section: ComponentLike<ModalSectionSignature>;
      }
    ];
    footer: [
      {
        buttonClass: string;
      }
    ];
  };
}

export default class Modal extends Component<ModalSignature> {
  // Service injections
  @service modal!: ModalService;

  // Untracked properties
  elementId = guidFor(this);
  escapeHandler!: (keyEvent: KeyboardEvent) => void | KeyboardEvent; // Set in `didInsertElement`
  style = style;

  // Tracked properties

  // Getters and setters
  get wrapperClass(): string {
    return this.args.wrapperClass ?? '';
  }

  get dataTestTag(): string {
    return this.args.testSelector || this.elementId;
  }

  get headingId() {
    return `${guidFor(this)}-heading`;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  close() {
    this.args.onClose();
  }

  @action
  bodyDidInsert(el: HTMLElement) {
    if (this.args.bodyDidInsert) {
      safeNext(this, () => {
        this.args.bodyDidInsert?.(el);
      });
    }
    this.modal.register(this);
  }

  @action
  bodyWillDestroy(this: Modal) {
    this.modal.unregister(this);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Modal: typeof Modal;
  }
}
