import Component from '@glimmer/component';

import { classes } from 'mobile-web/lib/utilities/classes';

import style from './index.m.scss';

export type Type = 'positive' | 'cautionary' | 'negative' | 'neutral';

interface Args {
  // Required arguments
  type: Type;

  // Optional arguments
}

interface Signature {
  Element: HTMLLIElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class TagListTag extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get rootClass(): string {
    return classes(this.style.root, this.style[this.args.type]);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TagList::Tag': typeof TagListTag;
  }
}
