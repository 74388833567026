import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

import BootstrapService from './bootstrap';
import ErrorService from './error';

export type HeroImage = {
  imageUri: string;
  altText: string;
};

export type LogoImage = {
  imageUriMobile: string;
  imageUriTablet: string;
};

export type MenuBanner = {
  imageUriMobile: string;
  imageUriTablet: string;
  altTextMobile: string;
  altTextTablet: string;
  urlDestination: string;
  urlNewWindow: string;
};

export type CmsRegistry = {
  heroImage: HeroImage;
  menuBanner: MenuBanner;
  logoImage: LogoImage;
};

export default class CmsContentService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service error!: ErrorService;

  // Untracked properties

  // Tracked properties

  // Getters and setters
  @alias('bootstrap.data.cmsContent')
  cmsContent?: CmsRegistry;

  // Lifecycle methods

  // Other methods
  getContent<K extends keyof CmsRegistry>(key: K): CmsRegistry[K] | undefined {
    if (!this.cmsContent) {
      return undefined;
    }
    try {
      if (this.cmsContent[key] && this.cmsContent[key] !== Object(this.cmsContent[key])) {
        throw new Error(`cmsContent for ${key} was not a well-formed object`);
      }
      return this.cmsContent[key] ?? undefined;
    } catch (e) {
      // Don't flash a user-facing banner for this, the UI already handles the fallback scenario of undefined
      this.error.sendExternalError(e);
      return undefined;
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'cms-content': CmsContentService;
  }
}
