import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { local } from 'mobile-web/decorators/storage';
import { isAdvance, isAtStore } from 'mobile-web/lib/order-criteria';
import { PreviousRoute } from 'mobile-web/lib/routing';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import FeaturesService from 'mobile-web/services/features';
import NotificationsService from 'mobile-web/services/notifications';
import { Notification } from 'mobile-web/services/notifications';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import { VendorSearchParams } from './vendor-search-results';

export default class IndexRoute extends Route {
  previousRoute?: PreviousRoute = undefined;

  @service features!: FeaturesService;
  @service notifications!: NotificationsService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service analytics!: AnalyticsService;
  @service bootstrap!: BootstrapService;
  @service orderCriteria!: OrderCriteriaService;

  @local localVendorSearchParams?: VendorSearchParams;

  beforeModel(transition: Transition) {
    if (!this.storage.redirectFromHome) {
      const mostRecent = this.storage.vendorHistory?.[this.storage.vendorHistory?.length - 1];

      if (mostRecent) {
        this.storage.redirectFromHome = true;
        this.analytics.trackEvent(AnalyticsEvents.LocalStorageAutoSkipToMenu);
        this.router.transitionTo('menu.vendor', mostRecent.slug);
      }
    }
    this.analytics.trackEvent(AnalyticsEvents.LocationCloudflareFound, () => ({
      [AnalyticsProperties.CloudflareGeolocationSuccess]: this.bootstrap.ipGeolocatedZip
        ? true
        : false,
      [AnalyticsProperties.CloudflareHeader]: this.bootstrap.cloudflareHeader,
    }));

    if (
      isAtStore(this.orderCriteria.defaultOrderCriteria) &&
      !isAdvance(this.orderCriteria.defaultOrderCriteria) &&
      this.features.flags['abtest-autodetect-location-homepage-cloudflare'] === 'Test Variant B' &&
      this.bootstrap.ipGeolocatedZip &&
      !this.storage.navigatedViaIpGeolocate &&
      transition.to.queryParams.noLocationsFound !== 'true'
    ) {
      //the usage of local storage here isn't desirable but prevents weird interactions with vendor search results's beforeModel relying on it
      this.localVendorSearchParams = {
        handoffMode: this.orderCriteria.defaultOrderCriteria.handoffMode,
        timeWantedType: this.orderCriteria.defaultOrderCriteria.timeWantedType,
        address: this.bootstrap.ipGeolocatedZip,
      };

      //this session storage variable allows vendor search form to know if it should pre-populate the field
      this.storage.navigatedViaIpGeolocate = true;

      //this query param is picked up by vendor search results, to know if we should redirect to menu one time
      this.router.transitionTo('vendor-search-results', {
        queryParams: { navigatedViaIpGeolocate: true },
      });
    } else if (transition.to.queryParams.noLocationsFound === 'true') {
      //this query param comes from vendor search results if there are no results
      this.localVendorSearchParams = { address: this.bootstrap.ipGeolocatedZip };
    }
  }

  model(): OrderSearchResultModel[] | PromiseLike<OrderSearchResultModel[]> {
    if (this.session.isLoggedIn) {
      return this.store
        .findAll('order-search-result')
        .then(orders => orders.filterBy('canUserCheckin', true));
    }

    return [];
  }

  afterModel() {
    const startupNotifications = this.storage.get('startupNotifications') ?? [];
    if (
      !Array.isArray(startupNotifications) ||
      !this.validStartupNotifications(startupNotifications)
    ) {
      return;
    }
    this.notifications.show(startupNotifications);
    this.storage.set('startupNotifications', []);
  }

  validStartupNotifications(notifications: Notification[]) {
    for (const notification of notifications) {
      const validNotificationObject =
        typeof notification === 'object' &&
        'level' in notification &&
        'message' in notification &&
        'type' in notification &&
        typeof notification.level === 'number' &&
        typeof notification.message === 'string' &&
        typeof notification.type === 'number';

      if (!validNotificationObject) {
        return validNotificationObject;
      }
    }

    return true;
  }
}
