import { service } from '@ember/service';
import DS from 'ember-data';

import { host } from 'mobile-web/lib/hybrid-util';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class TicketAdapter extends ApplicationAdapter {
  @service vendor!: VendorService;

  get buildURLPrefix(): string {
    return `${host()}/${this.namespace}`;
  }

  buildURL(
    _modelName: unknown,
    _id: unknown,
    snapshot: unknown,
    requestType: string,
    query?: { tableId?: string }
  ): string {
    if (requestType === 'basketFromExistingTicket') {
      return this.buildBasketFromExistingTicketURL(snapshot);
    } else if (requestType === 'query' && query?.tableId) {
      return this.buildQueryURL();
    }
    return super.buildURL(...arguments);
  }

  private buildBasketFromExistingTicketURL(snapshot: unknown) {
    const s = snapshot as DS.Snapshot<'ticket'>;
    const attributes = s.attributes();

    const vendorId = this.getVendorId();

    return `${this.buildURLPrefix}/basketFromExistingTicket?vendorId=${vendorId}&ticketNumber=${attributes.ticketNumber}`;
  }

  private buildQueryURL() {
    const vendorId = this.getVendorId();
    // We don't explicitly need a `tableId` query param.
    // Because this is `store.query`, the keys and values of `query`
    // automatically get appended as query params
    return `${this.buildURLPrefix}/vendors/${vendorId}/tickets`;
  }

  private getVendorId(): string {
    const vendorId = this.vendor.vendor?.id;
    if (!vendorId) {
      throw new Error('Vendor Id for Ticket Not Found');
    }
    return vendorId;
  }
}
