import Component from '@glimmer/component';

import type CategoryModel from 'mobile-web/models/category';

import style from './index.m.scss';

interface Args {
  // Required arguments
  category: CategoryModel;

  // Optional arguments
}
interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class SingleUseCategory extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  get hasProducts() {
    return this.args.category.products.length > 0;
  }

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SingleUseCategory: typeof SingleUseCategory;
  }
}
