import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import AnimationService from 'mobile-web/services/animation';

import style from './index.m.scss';

interface Args {
  // Required arguments
  createOloAccount: boolean;
  onChecked: Action;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class CreateOloAccountCheckbox extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service animation!: AnimationService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks
  @task *fade(el: HTMLElement) {
    yield this.animation.fadeTask.perform(el);
  }

  // Actions and helpers
  @action
  beginFadeIn(el: HTMLElement) {
    taskFor(this.fade).perform(el);
  }

  @action
  onDisclaimerClick() {
    this.analytics.trackEvent(AnalyticsEvents.OloAuthTermsOfServiceClicked, undefined, {
      bucket: 'olo-auth',
    });
  }

  @action
  onPrivacyPolicyClick() {
    this.analytics.trackEvent(AnalyticsEvents.OloAuthPrivacyPolicyClicked, undefined, {
      bucket: 'olo-auth',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CreateOloAccountCheckbox: typeof CreateOloAccountCheckbox;
  }
}
