import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import ChannelService from 'mobile-web/services/channel';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments
  model: {
    // Will be mutated
    optIn: boolean;
  };

  // Optional arguments
  guest?: boolean;
}

interface Signature {
  Args: Args;
}

export default class MarketingOptIn extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service session!: SessionService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get hideMarketingOptIn(): boolean {
    if (this.channel.settings?.hideMarketingOptIn) {
      return true;
    }

    if (!this.session.isLoggedIn && !this.channel.settings?.showGuestMarketingOptIn) {
      return true;
    }

    if (this.session.isOloAuthLogin) {
      return true;
    }

    return false;
  }

  get descriptionDictionaryKey(): string {
    return this.args.guest ? 'EMAIL_OPT_IN_GUEST_DESCRIPTION' : 'EMAIL_OPT_IN_DESCRIPTION';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleChange(e: Event) {
    this.args.model.optIn = (e.target as HTMLInputElement).checked;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MarketingOptIn: typeof MarketingOptIn;
  }
}
