import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { Mode } from 'mobile-web/components/sign-in-create-account-form';
import { OnPremiseExperience, PastOnPremiseOrder } from 'mobile-web/lib/on-premise';
import isSome from 'mobile-web/lib/utilities/is-some';
import BasketModel from 'mobile-web/models/basket';
import OrderModel from 'mobile-web/models/order';
import TicketModel from 'mobile-web/models/ticket';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import WindowService from 'mobile-web/services/window';

import style from './index.m.scss';

enum SentimentSurveyResult {
  ThumbsUp = 1,
  ThumbsDown = -1,
}

interface Args {
  // Required arguments
  currentVendor: Vendor;
  currency: string;

  // Optional arguments
  currentOrder?: OrderModel;
  currentBasket?: BasketModel;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class OnPremiseConfirmation extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service orderCriteria!: OrderCriteriaService;
  @service features!: FeaturesService;
  @service window!: WindowService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked private allOrders?: Array<PastOnPremiseOrder & { index: number }> = [];
  @tracked ticket?: TicketModel;
  @tracked showSentimentSurvey =
    this.onPremise.isPayAtTable &&
    this.channel.settings?.surveyUrl &&
    this.features.flags['olo-70640-sentiment-survey'] &&
    !this.storage.sentimentSurveyShown;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.onPremise.isPayAtTable) {
      this.analytics.trackEvent(AnalyticsEvents.PayAtTableOrderPaidPage, () => ({
        [AnalyticsProperties.TicketSubtotal]: args.currentOrder?.ticket?.totals?.subTotal,
      }));
    }
  }

  // Getters and setters

  get showCreateAccountButton() {
    return !this.session.isLoggedIn && this.session.signOnAllowed;
  }

  get showFeedback() {
    return !(this.hasUnpaidOpenCheck || this.onPremise.isPayAtTable);
  }

  get allTotal(): number | undefined {
    return this.allOrders?.reduce((total, o) => total + o.totalCost, 0);
  }

  get headerText(): string {
    if (this.onPremise.isPayAtTable) {
      return this.intl.t('mwc.postCheckout.thankyouHeader');
    }
    if (this.onPremise.multiOrder || this.hasUnpaidOpenCheck) {
      return this.intl.t('mwc.onPremiseConfirmation.orderIsInHeading', {
        name: this.session.user!.firstName,
      });
    }
    return this.intl.t('mwc.onPremiseConfirmation.thankYouHeading', {
      name: this.session.user!.firstName,
    });
  }

  get orderMoreButtonLabel(): string {
    if (this.hasPaidTicket) {
      return this.intl.t('mwc.favorites.emptyButton');
    }
    return this.intl.t('mwc.onPremiseConfirmation.orderMoreButton');
  }

  get summaryHeaderText(): string {
    if (this.onPremise.isPayAtTable) {
      return this.intl.t('mwc.onPremiseConfirmation.checkSummaryHeading');
    }
    return this.intl.t('mwc.onPremiseConfirmation.orderSummaryHeading');
  }

  get subheaderText(): string {
    if (this.onPremise.isPayAtTable) {
      return this.intl.t('mwc.onPremiseConfirmation.payAtTableSuccessfullyPaid');
    } else if (this.onPremise.hasOpenCheck && this.hasPaidTicket) {
      return this.intl.t('mwc.onPremiseConfirmation.openCheckSuccessfullyPaid');
    }
    return this.intl.t('mwc.onPremiseConfirmation.receivedText');
  }

  get lastOrder(): OrderModel | undefined | null {
    if (!this.session.lastOrderId) {
      return undefined;
    }

    return this.store.peekRecord('order', this.session.lastOrderId);
  }

  get hasPaidTicket(): boolean {
    return isSome(this.args.currentOrder?.ticket);
  }

  get hasUnpaidOpenCheck(): boolean {
    return this.onPremise.hasOpenCheck && !this.hasPaidTicket;
  }

  get showThanksHandoffInstructions(): boolean {
    if (this.onPremise.isPayAtTable) {
      return false;
    }
    return !this.hasUnpaidOpenCheck;
  }

  // Lifecycle methods

  // Other methods
  loadItems = async () => {
    if (this.onPremise.multiOrder) {
      // We can assume that `pastOnPremiseOrders` has the current order because of
      // the thank-you route's afterModel
      const pastOrders = this.onPremise.multiOrder!.pastOrders!;
      this.allOrders = pastOrders
        .reverse()
        .map((o, i, array) => ({ ...o, index: array.length - i }));
    } else if (this.hasPaidTicket) {
      this.ticket = this.args.currentOrder?.ticket;
    } else if (this.onPremise.hasOpenCheck) {
      this.ticket = await this.basket.getOpenCheckItems();
    }
  };

  // Tasks

  // Actions and helpers
  @action
  orderMore() {
    const slug = this.args.currentVendor.get('slug');

    // Menu filtering depends on search criteria being set properly
    if (this.onPremise.isEnabled && !this.hasUnpaidOpenCheck) {
      this.orderCriteria.updateSearchOrderCriteria('DineIn');
    }

    if (slug) {
      this.router.transitionTo('menu.vendor', slug);
    } else {
      this.router.transitionTo('index');
    }
    if (this.onPremise.experienceType === OnPremiseExperience.MultiOrder) {
      this.analytics.trackEvent(AnalyticsEvents.OrderMore);
    }
  }

  @action
  closeCheck() {
    this.analytics.trackEvent(AnalyticsEvents.OpenCheckFinishPay, () => ({
      [AnalyticsProperties.OpenCheckRoundsOrdered]: this.onPremise.openCheckRoundsOrdered ?? 0,
    }));
    this.router.transitionTo('checkout');
    // will revalidate the basket on checkout route
  }

  @action
  upgradeAccount() {
    if (this.session.internalSignOnAllowed) {
      this.router.transitionTo('login', {
        queryParams: { mode: Mode.CreateAccount, upgrade: true },
      });
    } else if (this.session.hasLoginProvider) {
      const provider = this.session.loginProviders![0];
      this.session.externalLoginWithNextRoute(provider.slug);
    }
  }

  @action
  dismissSentimentSurvey() {
    this.analytics.trackEvent(AnalyticsEvents.SentimentSurveyDismissed);
    this.showSentimentSurvey = false;
  }

  @action
  handleSentimentSurveyFeedback(result: SentimentSurveyResult) {
    const redirectUrl = encodeURIComponent(`${this.window.location().href}?surveyCompleted`);
    const emailAddress = encodeURIComponent(this.session.user?.emailAddress ?? '');
    const surveyUrl = `${this.channel.settings?.surveyUrl}?redirectUrl=${redirectUrl}&emailAddress=${emailAddress}`;
    if (!surveyUrl) {
      return;
    }

    const analyticsEventName =
      result === SentimentSurveyResult.ThumbsUp
        ? AnalyticsEvents.SentimentSurveyThumbsUp
        : AnalyticsEvents.SentimentSurveyThumbsDown;
    this.analytics.trackEvent(analyticsEventName);

    this.window.location().assign(surveyUrl);
  }

  @action
  surveyShown() {
    if (this.showSentimentSurvey) {
      this.analytics.trackEvent(AnalyticsEvents.SentimentSurveyShown);
    }
    this.storage.sentimentSurveyShown = true;
  }

  orderNumber = (order: PastOnPremiseOrder & { index: number }) => {
    const last3OrderNum = this.features.flags['three-digit-order-number-olo-61552'];

    return last3OrderNum ? order.displayId.substring(order.displayId.length - 3) : order.index;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OnPremise::Confirmation': typeof OnPremiseConfirmation;
  }
}
